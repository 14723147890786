import React, { Fragment } from 'react'
import { useStyles2, Text, Tooltip, Box } from '@grafana/ui'

import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'
import { InfoCircleFill } from '@styled-icons/bootstrap'
import { DewellSetting } from '../../interfaces/dewell'

interface DWellTimeSettingInfoProps {
  data: DewellSetting[]
}

const DWellTimeSettingInfo: React.FC<DWellTimeSettingInfoProps> = ({ data }) => {
  const style = useStyles2(getStyles)

  const renderText = (field: DewellSetting, index: number) => {
    if (index === 0) {
      return ` <${field.less_than} min `
    } else if (index === data.length - 1) {
      return ` ≥${field.greater_than_or_equal_to}`
    } else {
      return `${field.greater_than_or_equal_to} - ${field.less_than} min `
    }
  }

  return (
    <div className={style.container}>
      <div className={style.space}>
        <Box display="flex">
          <Text data-testid="info-title" element="h4">
            Dwell Time interval
          </Text>
          <Tooltip content="Dwell Time Data Display according to interval Settings">
            <InfoCircleFill data-testid="tooltip" className={style.icon} />
          </Tooltip>
        </Box>
      </div>
      <div className={style.itemContainer} data-testid={`interval-itemContainer`}>
        {data?.length ? (
          data.map((field, index) => (
            <div className={style.item} key={index} data-testid={`interval-item-${index}`}>
              <Text>
                {`Interval ${field.interval_id}:`}&nbsp;
                {renderText(field, index)}
              </Text>
            </div>
          ))
        ) : (
          <Fragment />
        )}
      </div>
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    itemContainer: css`
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: ${theme.spacing(2)};
      align-items: inherit;
      ${theme.breakpoints.down('md')} {
        grid-template-columns: repeat(4, 1fr);
      }
      ${theme.breakpoints.down('sm')} {
        grid-template-columns: repeat(2, 1fr);
      }
    `,
    item: css`
      border-color: ${theme.colors.border.medium};
      border-style: solid;
      border-radius: 6px;
      padding: ${theme.spacing(1)};
      text-align: center;
    `,
    icon: css`
      width: 20px;
      margin-left: ${theme.spacing(1)};
      color: ${theme.colors.info.main};
    `,
    space: css`
      margin-bottom: ${theme.spacing(3)};
    `,
  }
}

export default DWellTimeSettingInfo
