import React from 'react'
import { css } from '@emotion/css'
import { Button, Field, Form, Input, Modal, useStyles2 } from '@grafana/ui'
import password from 'secure-random-password'
import { User } from '../../interfaces/settingUser'

interface ResetPasswordModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onSubmit: (newPassword: string) => void
  user?: User
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({ isOpen, setIsOpen, onSubmit, user }) => {
  const styles = useStyles2(getStyles)

  const newRandomPassword: string = password.randomPassword({
    length: 8,
    characters: [password.upper, password.digits, password.symbols, password.lower],
  })

  return (
    <Modal
      title="Password modification tips"
      isOpen={isOpen}
      onDismiss={() => setIsOpen(!isOpen)}
      className={styles.modelWrapper}
    >
      <Form onSubmit={(data: any) => onSubmit(data.newPassword)}>
        {({ register }) => {
          return (
            <>
              <Field label="User Name:">
                <Input {...register('name')} value={user?.user_name} disabled />
              </Field>

              <Field label="New Password:">
                <Input {...register('newPassword')} value={newRandomPassword} readOnly />
              </Field>

              <Modal.ButtonRow>
                <Button variant="secondary" fill="outline" onClick={() => setIsOpen(!isOpen)}>
                  Cancel
                </Button>
                <Button onClick={() => onSubmit(newRandomPassword)}>Confirm</Button>
              </Modal.ButtonRow>
            </>
          )
        }}
      </Form>
    </Modal>
  )
}

const getStyles = () => ({
  modelWrapper: css`
    max-width: fit-content;
  `,
})

export default ResetPasswordModal
