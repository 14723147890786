import React from 'react'
import { Field, useStyles2 } from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'

interface HorizontalFieldProps {
  label: string
  children: any
  required?: boolean
}

const HorizontalField: React.FC<HorizontalFieldProps> = ({ label, children, required }) => {
  const style = useStyles2(getStyles)

  return (
    <Field className={style.container} label={label} horizontal required={required}>
      {children}
    </Field>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    justify-content: flex-start;
    column-gap: ${theme.spacing(2)};
    align-items: center;

    label:first-child {
      width: 70px;
      display: flex;
      text-align: right;
      justify-content: end;
      ${theme.breakpoints.down('md')} {
        justify-content: left;
      }
    }

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
})

export default HorizontalField
