import React from 'react'
import { CellProps, Checkbox, Dropdown, IconButton, Menu, InteractiveTable, Column, Switch } from '@grafana/ui'
import { User } from '../../../interfaces/settingUser'

interface CustomColumn<T extends object> extends Omit<Column<T>, 'header'> {
  header: string | (() => React.ReactNode)
}

interface UserTableProps {
  usersData: User[]
  userSelectedList: number[]
  selectAll: boolean
  handleSelectUser: (userId: number) => void
  handleSelectAll: () => void
  onChangeUserStatus: (userId: number, status: boolean) => void
  onEditUser: (user: User) => void
  onResetPassword: (user: User) => void
}

const UserTable: React.FC<UserTableProps> = ({
  usersData,
  userSelectedList,
  selectAll,
  handleSelectUser,
  handleSelectAll,
  onChangeUserStatus,
  onEditUser,
  onResetPassword,
}) => {
  const StatusCell = ({
    row: {
      original: { user_id, is_admin_grafana },
    },
    value,
  }: CellProps<User>) => (
    <Switch
      disabled={is_admin_grafana}
      value={value}
      onChange={() => !is_admin_grafana && onChangeUserStatus(user_id, !value)}
    />
  )

  const OperationCell = ({ row: { original } }: CellProps<User>) => {
    return original?.is_admin_grafana ? (
      <IconButton tooltip="Open menu" variant="secondary" name="ellipsis-v" disabled />
    ) : (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item label="Edit information" icon="edit" onClick={() => onEditUser(original as User)} />
            <Menu.Item label="Reset password" icon="history" onClick={() => onResetPassword(original as User)} />
          </Menu>
        }
      >
        <IconButton tooltip="Open menu" variant="secondary" name="ellipsis-v" />
      </Dropdown>
    )
  }

  const SelectionCell = ({
    row: {
      original: { user_id },
    },
  }: CellProps<User>) => (
    <Checkbox
      checked={userSelectedList?.includes(user_id)}
      onChange={() => handleSelectUser(user_id)}
      data-testid={`select-all-checkbox-${user_id}`}
    />
  )

  const columns: Array<CustomColumn<User>> = [
    {
      id: 'selection',
      header: () => <Checkbox checked={selectAll} onChange={handleSelectAll} data-testid="select-all-checkbox" />,
      cell: SelectionCell,
    },
    { id: 'user_id', header: 'User ID', sortType: 'number' },
    { id: 'organization', header: 'Organization belonging to', sortType: 'string' },
    { id: 'user_login', header: 'Login Account', sortType: 'string' },
    { id: 'user_name', header: 'User Name', sortType: 'string' },
    { id: 'role_name', header: 'User Role(s)', sortType: 'string' },
    { id: 'status', header: 'Status', cell: StatusCell },
    { id: 'operation', header: 'Operation', cell: OperationCell },
  ]

  return <InteractiveTable columns={columns as Array<Column<User>>} data={usersData} getRowId={(r: any) => r.user_id} />
}

export default UserTable
