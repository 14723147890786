import { fetchGet } from '../utils/utils.apiControl'
import { T3StoreApiConfig, InjectionApiResponse } from '../interfaces/apiConfig'
import { createApiConfig } from './peoplecountingApi'
import { QueriesDataResponse } from '../interfaces/apiQueries'

type GetQueriesResponse<T> = { result: InjectionApiResponse<T> | null; error: any }

const getQueries = async (
  params: Record<string, any>,
  t3StoreApiConfig: T3StoreApiConfig
): Promise<GetQueriesResponse<QueriesDataResponse>> => {
  let result: InjectionApiResponse<QueriesDataResponse> | null = null
  let error = null

  try {
    result = await fetchGet<InjectionApiResponse<QueriesDataResponse>>(
      `${t3StoreApiConfig.apiUrl}/queries`,
      params,
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: unknown) {
    console.error(`Error getQueries with params ${JSON.stringify(params)}`, err)
    error = err
  }

  return { result, error }
}

export { getQueries }
