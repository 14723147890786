import React, { ReactNode } from 'react'
import { useStyles2 } from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'

interface DWellTimeProps {
  children: ReactNode
}

const DWellTimeContainer: React.FC<DWellTimeProps> = ({ children }) => {
  const style = useStyles2(getStyles)

  return <div className={style.container}>{children}</div>
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      justify-content: flex-start;
      column-gap: ${theme.spacing(2)};
      align-items: center;
    `,
  }
}

export default DWellTimeContainer
