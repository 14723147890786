const removeEmptyInObject = (obj: any) => {
  let result: any = {}

  if (obj && typeof obj === 'object') {
    Object?.keys(obj).forEach((key) => {
      const data = obj[key]
      if (data || typeof data === 'number' || typeof data === 'boolean') {
        result[key] = data
      }
    })
  }

  return result
}

export { removeEmptyInObject }
