import { useState } from 'react'

interface PopupState {
  isOpen: boolean
  title: string
  message: string
}

interface DisplayPopupParams {
  title?: string
  message?: string
}

const usePopup = () => {
  const [popupState, setPopupState] = useState<PopupState>({
    isOpen: false,
    title: '',
    message: '',
  })

  const displayPopup = ({ title = '', message = '' }: DisplayPopupParams) => {
    setPopupState({
      isOpen: true,
      title,
      message,
    })
  }

  const hidePopup = () => {
    setPopupState({
      isOpen: false,
      title: '',
      message: '',
    })
  }

  return {
    popupState,
    displayPopup,
    hidePopup,
  }
}

export default usePopup
