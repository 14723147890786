import React from 'react'
import { Button, Modal, useStyles2 } from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'

import usePopup from '../../hooks/usePopup'

interface PopupProps {
  isOpen?: boolean
  title?: string | React.JSX.Element
  message?: string | React.JSX.Element
  cancelText?: string
  okText?: string
  width?: string
  variant?: 'primary' | 'secondary' | 'destructive' | 'success'
  onCancel?: () => void
  onOk?: () => void
}

const Popup: React.FC<PopupProps> = ({
  title = '',
  message = '',
  isOpen = false,
  okText = '',
  cancelText = '',
  variant = 'primary',
  onOk,
  onCancel,
}) => {
  const style = useStyles2(getStyles)

  return (
    <Modal className={`${style.container}`} title={title} isOpen={isOpen} onClickBackdrop={onCancel}>
      <div>{message}</div>
      <Modal.ButtonRow>
        {cancelText && (
          <Button variant="secondary" fill="outline" onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        {okText && (
          <Button onClick={onOk} variant={variant}>
            {okText}
          </Button>
        )}
      </Modal.ButtonRow>
    </Modal>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    top: 35%;
    width: min-content;
    min-width: 500px;

    .css-oallay {
      display: none;
    }
  `,
})

export default Popup
export { usePopup }
