import { isNumber } from './utils.number'
import { DewellSetting } from '../interfaces/dewell'

const validateIntervals = (intervals: DewellSetting[]): boolean => {
  let isValid = true

  for (let i = 0; i < intervals.length; i++) {
    const currentInterval = intervals?.[i]
    const nextInterval = intervals?.[i + 1]

    // check empty data
    if (!isNumber(currentInterval?.greater_than_or_equal_to) || !isNumber(currentInterval?.less_than)) {
      isValid = false
      break
    }

    // check greater_than_or_equal_to >= less_than
    if (nextInterval && currentInterval.greater_than_or_equal_to >= currentInterval.less_than) {
      isValid = false
      break
    }

    // check current less_than >  next greater_than_or_equal_to
    if (nextInterval && currentInterval.less_than > nextInterval.greater_than_or_equal_to) {
      isValid = false
      break
    }
  }

  return isValid
}

export { validateIntervals }
