import React, { useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import { GrafanaTheme2 } from '@grafana/data'
import { ControlledCollapse, useStyles2, InputControl, RadioButtonList, Button } from '@grafana/ui'
import { Control } from 'react-hook-form'
import HighlightedText from '../highlightText'
import { FormattedStore, FormattedParentStore } from '../../interfaces/settingUser'

interface StoreListProps {
  data: FormattedStore[]
  control: Control<any>
  name: string
  setDefaultValue: any
  searchingText?: string
  addStore?: boolean
  handleAddStore?: (parentStore: FormattedParentStore) => void | undefined
}

const SingleStoreList: React.FC<StoreListProps> = ({
  data,
  control,
  name,
  searchingText,
  setDefaultValue,
  addStore,
  handleAddStore,
}) => {
  const initValue = data?.[0]?.store_id

  const style = useStyles2(getStyles)
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [selected, setSelected] = useState<number>(initValue)

  const effectRan = useRef(false)

  const convertStoreToOptions = (store: FormattedStore) => {
    return [
      {
        label: (<HighlightedText text={`${store.store_name}`} highlight={searchingText} />) as any,
        value: store.store_id,
      },
    ]
  }

  const convertBranchsToOptions = (store: FormattedStore) => {
    return store.branchs.map((branch) => ({
      label: (<HighlightedText text={`${branch.store_name} (${branch.store_id})`} highlight={searchingText} />) as any,
      value: branch.store_id,
    }))
  }

  useEffect(() => {
    if (!effectRan.current) {
      setDefaultValue(name, initValue)
      effectRan.current = true
    }
  }, [name, initValue, setDefaultValue])

  return (
    <div className={style.storesContainer}>
      <InputControl
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {data.map((store) => (
              <ControlledCollapse
                key={store.store_id}
                className={style.collapse}
                label={
                  <RadioButtonList
                    {...field}
                    options={convertStoreToOptions(store)}
                    value={selected}
                    onChange={(value) => {
                      setSelected(value)
                      field.onChange(value)
                    }}
                  />
                }
                isOpen={isOpen}
                onToggle={() => setIsOpen(!isOpen)}
              >
                {addStore && handleAddStore && (
                  <div className={style.storeHeader}>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleAddStore({ storeId: store.store_id, storeName: store.store_name })}
                    >
                      +Add Store
                    </Button>
                  </div>
                )}
                <RadioButtonList
                  className={style.radioButtonList}
                  {...field}
                  options={convertBranchsToOptions(store)}
                  value={selected}
                  onChange={(value) => {
                    setSelected(value)
                    field.onChange(value)
                  }}
                />
              </ControlledCollapse>
            ))}
          </>
        )}
      />
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  storesContainer: css`
    /* height: 0; */
    height: 100vh;
    min-height: 100%;
    overflow: auto;
    border: 1px solid ${theme.colors.border.weak};
  `,
  radioButtonList: css`
    padding-left: ${theme.spacing(1)};
  `,
  collapse: css`
    border: none;
  `,
  storeHeader: css`
    position: absolute;
    top: 0;
    right: 0;
    padding: ${theme.spacing(1)};
  `,
})

export default SingleStoreList
