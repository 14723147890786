import React, { useCallback, useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import { useForm } from 'react-hook-form'
import { GrafanaTheme2 } from '@grafana/data'
import {
  Button,
  Divider,
  EmptySearchResult,
  Icon,
  InlineField,
  InlineFieldRow,
  Input,
  InputControl,
  Pagination,
  Select,
  Text,
  useStyles2,
} from '@grafana/ui'
import { getQueries } from '../../apis/queriesApi'
import { resetUserPasswordApi, updateUserApi } from '../../apis/userApi'
import { useAppSettingContext } from '../../contexts/appSettingContext'
import { SingleStoreList } from '../storeList'
import Popup, { usePopup } from '../popup'
import UserTable from './userTable'
import ResetPasswordModal from './resetPasswordModal'
import { mapQueriesValue } from '../../utils/utils.mapValue'
import { MODAL_TYPE, MODAL_TYPE_NAME, getModalMessage } from '../../utils/utils.modalType'
import { USER_STATUS_OPTIONS } from '../../constants/userStatusOptionsConstants'
import { SelectOptions } from '../../interfaces/form'
import { FormattedStore, User, EditUserState } from '../../interfaces/settingUser'
import { SettingPageEnum } from '../../pages/user/setting'
import { Profile } from '../../interfaces/profile'

type FormValues = {
  storeIds: number[] | []
  accountNumber: string
  name: string
  status: string
  role: string
  userId: string
}

interface SearchProps {
  stores: FormattedStore[]
  roles: SelectOptions[]
  setSettingPage: (page: any) => void
  setEditUserState: (state: EditUserState) => void
  userProfile: Profile | null
}

const SearchUserPage: React.FC<SearchProps> = ({ stores, roles, setSettingPage, userProfile, setEditUserState }) => {
  const style = useStyles2(getStyles)
  const { t3StoreApiConfig } = useAppSettingContext()
  const { popupState, displayPopup, hidePopup } = usePopup()

  const pageRef = useRef(1)
  const effectRan = useRef(false)
  const processingUserRef = useRef<{ userId: number; status: boolean } | null>(null)

  const [userSelectedList, setUserSelectedList] = useState<number[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [filteredStores, setFilteredStores] = useState<FormattedStore[]>(stores)
  const [highlightText, setHighlightText] = useState<string>('')
  const [usersData, setUsersData] = useState<User[]>([])
  const [roleSelected, setRoleSelected] = useState<string | number | undefined>('')
  const [statusSelected, setStatusSelected] = useState<string | number | undefined>('')
  const [page, setPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [isOpenResetPasswordModal, setIsOpenResetPasswordModal] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<User>()
  const [modalType, setModalType] = useState<string | null>(null)

  const { control, getValues, setValue, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      storeIds: [],
      accountNumber: '',
      name: '',
      status: '',
      role: '',
      userId: '',
    },
  })

  const handleSelectUser = (userId: number) => {
    setUserSelectedList((prev) => (prev.includes(userId) ? prev.filter((id) => id !== userId) : [...prev, userId]))
  }

  const handleSelectAll = () => {
    setUserSelectedList(selectAll ? [] : usersData.map((user) => user.user_id))
    setSelectAll(!selectAll)
  }

  const getUserOrganization = (id: number | number[], userData: User): string | null => {
    const storeId = Array.isArray(id) ? id[0] : id
    return storeId === userData.store_id
      ? userData.store_name
      : storeId === userData.parent_store_id
      ? userData.parent_store_name
      : null
  }

  const onEditUser = (user: User) => {
    setEditUserState({ isEditUser: true, user })
    setSettingPage(SettingPageEnum.ADD)
  }

  const onResetPassword = (user: User) => {
    setSelectedUser(user)
    setIsOpenResetPasswordModal(true)
  }

  const onSubmitResetPassword = async (newPassword: string) => {
    const { error } = await resetUserPasswordApi(selectedUser?.grafana_user_id, newPassword)

    if (error) {
      console.error('onSubmitResetPassword:', error)
      setModalType(MODAL_TYPE_NAME.API_ERROR)
      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
        message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
      })
    }
    setSelectedUser(undefined)
    setIsOpenResetPasswordModal(false)
  }

  const handleChangeUserStatus = async () => {
    const { userId, status } = processingUserRef.current!
    const { error } = await updateUserApi(
      userId,
      { update_by: userProfile?.name || userProfile?.login || userProfile?.email || 'Anonymous', status },
      t3StoreApiConfig
    )
    processingUserRef.current = null

    queryUserData()
    hidePopup()

    if (error) {
      console.error('onChangeUserStatus:', error)
      setModalType(MODAL_TYPE_NAME.API_ERROR)
      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
        message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
      })
    }
  }

  const onChangeUserStatus = async (userId: number, status: boolean) => {
    processingUserRef.current = { userId, status }
    if (status) {
      handleChangeUserStatus()
    } else {
      setModalType(MODAL_TYPE_NAME.CONFIRM)
      displayPopup({
        title: 'Disable user',
        message: 'Are you sure you want to disable this user?',
      })
    }
  }

  const queryUserData = useCallback(async () => {
    const formValues = getValues()
    const isSingleStoreId = typeof formValues?.storeIds === 'number'
    const isMultipleStoreIds = Array.isArray(formValues?.storeIds) && formValues?.storeIds?.length > 0
    const paramInput = JSON.stringify({
      user_id: formValues?.userId || null,
      user_name: formValues?.name || null,
      user_login: formValues?.accountNumber || null,
      role_id: formValues?.role || null,
      status: formValues?.status || null,
      store_ids: isSingleStoreId ? formValues.storeIds : isMultipleStoreIds ? formValues.storeIds.join(',') : null,
    })

    const { result: searchResult } = await getQueries(
      { q: 'iot.func_get_users', param_input: paramInput, page: pageRef.current, limit: 50 },
      t3StoreApiConfig
    )

    const searchData = mapQueriesValue<User>(searchResult?.data)
    const fillsearchData = searchData.map((user) => ({
      ...user,
      organization: getUserOrganization(formValues.storeIds, user),
    }))
    const totalPages = searchResult?.data?.paging?.total_pages || 1

    setUsersData(fillsearchData)
    setTotalPage(totalPages)
  }, [t3StoreApiConfig, getValues])

  const handleSearchStore = (e: React.FormEvent<HTMLInputElement>) => {
    const inputText = (e.target as HTMLInputElement).value
    if (inputText === '') {
      setFilteredStores(stores)
    } else {
      const result = stores
        .map((store) => ({
          store_id: store.store_id,
          store_name: store.store_name,
          branchs: store.branchs.filter(
            (st) =>
              st.store_name.toLowerCase().includes(inputText.toLowerCase()) ||
              st.store_id.toString().includes(inputText)
          ),
        }))
        .filter((store) => store.store_name.length > 0)

      setFilteredStores(result)
    }
    setHighlightText(inputText)
  }

  const onSubmit = useCallback(() => {
    pageRef.current = 1
    setPage(1)
    queryUserData()
  }, [queryUserData])

  const handleChangePagination = (toPage: number) => {
    pageRef.current = toPage
    setPage(toPage)
    queryUserData()
  }

  useEffect(() => {
    if (!effectRan.current) {
      queryUserData()
      effectRan.current = true
    }
  }, [queryUserData])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'storeIds') {
        onSubmit()
      }
    })
    return () => subscription.unsubscribe()
  }, [onSubmit, watch])

  const handleClickAddUser = () => {
    setSettingPage(SettingPageEnum.ADD)
    setEditUserState({ isEditUser: false, user: null })
  }

  const modalFactory = () => {
    switch (modalType) {
      case MODAL_TYPE_NAME.CONFIRM:
        return (
          <Popup
            {...popupState}
            okText="Disable user"
            onOk={handleChangeUserStatus}
            cancelText="Cancel"
            onCancel={hidePopup}
            variant="destructive"
          />
        )
      case MODAL_TYPE_NAME.API_ERROR:
        return <Popup {...popupState} okText="OK" onOk={hidePopup} />
      default:
        return null
    }
  }

  return (
    <div className={style.pageWrapper}>
      <div className={style.buttonGroup}>
        <Button size="sm">Batch Import</Button>
        <Button size="sm" fill="outline">
          Import Record
        </Button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={style.mainContainer}>
        <section className={style.sidebarContainer}>
          <div>
            <Input placeholder="input store name..." prefix={<Icon name="search" />} onChange={handleSearchStore} />
          </div>
          {filteredStores?.length > 0 && (
            <SingleStoreList
              data={filteredStores}
              control={control}
              name="storeIds"
              searchingText={highlightText}
              setDefaultValue={setValue}
            />
          )}
        </section>

        <section className={style.dataContainer}>
          <div className={style.filterSection}>
            <InlineFieldRow>
              <InlineField label="Account number:" transparent>
                <InputControl render={({ field }) => <Input {...field} />} control={control} name="accountNumber" />
              </InlineField>
              <InlineField label="Name:" transparent>
                <InputControl render={({ field }) => <Input {...field} />} control={control} name="name" />
              </InlineField>
              <InlineField label="Status:" transparent>
                <InputControl
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={USER_STATUS_OPTIONS}
                      onChange={(e) => {
                        field.onChange(e.value)
                        setStatusSelected(e.value)
                      }}
                      value={statusSelected}
                    />
                  )}
                  control={control}
                  name="status"
                />
              </InlineField>
            </InlineFieldRow>
            <InlineFieldRow>
              <InlineField label="Role:" transparent>
                <InputControl
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={roles}
                      onChange={(e) => {
                        field.onChange(e.value)
                        setRoleSelected(e.value)
                      }}
                      value={roleSelected}
                    />
                  )}
                  control={control}
                  name="role"
                />
              </InlineField>
              <InlineField label="User ID:" transparent>
                <InputControl render={({ field }) => <Input {...field} />} control={control} name="userId" />
              </InlineField>
            </InlineFieldRow>
            <div className={style.buttonGroup}>
              <Button type="submit" icon="search">
                Search
              </Button>
              <Button type="button" fill="outline" icon="plus" onClick={handleClickAddUser}>
                Add User
              </Button>
            </div>
          </div>

          <div>
            <div className={style.tableHeader}>
              <Text element="h5">Account List</Text>
              <div className={style.buttonGroup}>
                <Button size="sm" fill="outline">
                  Export
                </Button>
                <Button size="sm" fill="outline">
                  Operation Record
                </Button>
              </div>
            </div>
            <Divider />
            {usersData.length > 0 ? (
              <>
                <UserTable
                  usersData={usersData}
                  userSelectedList={userSelectedList}
                  selectAll={selectAll}
                  handleSelectUser={handleSelectUser}
                  handleSelectAll={handleSelectAll}
                  onChangeUserStatus={onChangeUserStatus}
                  onEditUser={onEditUser}
                  onResetPassword={onResetPassword}
                />
                <Divider spacing={0} />
                <Pagination
                  className={style.pagination}
                  currentPage={page}
                  numberOfPages={totalPage}
                  onNavigate={handleChangePagination}
                />
              </>
            ) : (
              <EmptySearchResult>No users found</EmptySearchResult>
            )}
          </div>
        </section>
      </form>

      <ResetPasswordModal
        isOpen={isOpenResetPasswordModal}
        setIsOpen={setIsOpenResetPasswordModal}
        onSubmit={(newPassword) => onSubmitResetPassword(newPassword)}
        user={selectedUser}
      />
      {modalFactory()}
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  pageWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(6)};
    height: 100%;
  `,
  mainContainer: css`
    display: grid;
    grid-template-columns: minmax(378px, auto) 1fr;
    gap: ${theme.spacing(4)};
    height: 100%;
  `,
  sidebarContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    overflow: hidden;
    padding: ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(8)} ${theme.spacing(1)};
  `,
  dataContainer: css`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    gap: 3rem;
    overflow: auto;
  `,
  filterSection: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(1)};
  `,
  tableHeader: css`
    display: flex;
    justify-content: space-between;
  `,
  buttonGroup: css`
    display: flex;
    gap: ${theme.spacing(2)};
  `,
  pagination: css`
    margin-top: ${theme.spacing(2)};
  `,
})

export default SearchUserPage
