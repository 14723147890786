import React, { useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import { GrafanaTheme2 } from '@grafana/data'
import { Control, ControllerRenderProps } from 'react-hook-form'
import { ControlledCollapse, useStyles2, InputControl, Checkbox, VerticalGroup } from '@grafana/ui'

import HighlightedText from '../highlightText'

import { FormattedStore } from '../../interfaces/settingUser'

interface StoreListProps {
  data: FormattedStore[]
  control: Control<any>
  name: string
  setDefaultValue?: any
  searchingText?: string
}

const MultipleStoreList: React.FC<StoreListProps> = ({ data, control, name, searchingText, setDefaultValue }) => {
  const style = useStyles2(getStyles)
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const isCheckedAll = (store: FormattedStore, field: ControllerRenderProps<any, string>) => {
    const allBranchIds = store.branchs.map((branch: any) => branch.store_id)
    const isMainStoreChecked = field.value.includes(store.store_id)
    const areSomeBranchesUnchecked = allBranchIds.some((id: number) => !field.value.includes(id))
    return isMainStoreChecked && areSomeBranchesUnchecked
  }

  const handleSelectAll = (store: FormattedStore, field: ControllerRenderProps<any, string>) => {
    const allBranchIds = store.branchs.map((branch: any) => branch.store_id)
    const newValue = field.value.includes(store.store_id)
      ? field.value.filter((id: number) => !allBranchIds.includes(id) && id !== store.store_id)
      : [...new Set([...field.value, store.store_id, ...allBranchIds])]
    field.onChange(newValue)
  }

  const effectRan = useRef(false)
  useEffect(() => {
    if (!effectRan.current && setDefaultValue) {
      const initialValues = data.reduce((acc: number[], store: FormattedStore) => {
        const branchIds = store.branchs.map((branch: any) => branch.store_id)
        return [...acc, store.store_id, ...branchIds]
      }, [])
      setDefaultValue(name, initialValues)
      effectRan.current = true
    }
  }, [data, name, setDefaultValue])

  return (
    <div className={style.storesContainer}>
      <InputControl
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {data.map((store) => (
              <ControlledCollapse
                key={store.store_id}
                className={style.collapse}
                label={
                  <Checkbox
                    {...field}
                    value={field.value.includes(store.store_id)}
                    checked={field.value.includes(store.store_id)}
                    label={(<HighlightedText text={`${store.store_name}`} highlight={searchingText} />) as any}
                    onChange={() => handleSelectAll(store, field)}
                    indeterminate={isCheckedAll(store, field)}
                  />
                }
                isOpen={isOpen}
                onToggle={() => setIsOpen(!isOpen)}
              >
                <VerticalGroup>
                  {store?.branchs?.map((branch) => (
                    <Checkbox
                      key={branch.store_id}
                      {...field}
                      value={field.value.includes(branch.store_id)}
                      checked={field.value.includes(branch.store_id)}
                      label={
                        (
                          <HighlightedText
                            text={`${branch.store_name} (${branch.store_id})`}
                            highlight={searchingText}
                          />
                        ) as any
                      }
                      onChange={() => {
                        const newValue = field.value.includes(branch.store_id)
                          ? field.value.filter((id: number) => id !== branch.store_id)
                          : [...field.value, branch.store_id]
                        field.onChange(newValue)
                      }}
                    />
                  ))}
                </VerticalGroup>
              </ControlledCollapse>
            ))}
          </>
        )}
      />
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  storesContainer: css`
    height: 0;
    min-height: 100%;
    overflow: auto;
    border: 1px solid ${theme.colors.border.weak};
  `,
  radioButtonList: css`
    padding-left: ${theme.spacing(1)};
  `,
  collapse: css`
    border: none;
  `,
})

export default MultipleStoreList
