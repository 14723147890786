import React from 'react'
import { useStyles2, Box } from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'

const DeduplicationPeroidInfo: React.FC = () => {
  const style = useStyles2(getStyles)

  return (
    <div className={style.container}>
      <div className={style.space}>
        <Box display="flex">
          <div data-testid="deduplicationPeroid-info" className={style.info}>
            Deduplication Peroid: Same Visitors visit same store several times in this peroid,will be counted as once.
          </div>
        </Box>
      </div>
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    space: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    info: css`
      background: ${theme.colors.background.canvas};
      padding: ${theme.spacing(1)} ${theme.spacing(2)};
      border-radius: 6px;
    `,
  }
}

export default DeduplicationPeroidInfo
