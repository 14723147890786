import React from 'react'
import { GrafanaTheme2 } from '@grafana/data'
import { useStyles2 } from '@grafana/ui'
import { css } from '@emotion/css'

const getStyles = (theme: GrafanaTheme2) => ({
  hilightText: css`
    color: ${theme.colors.warning.text};
  `,
})

type HighlightedTextProps = {
  text: string
  highlight?: string
}

const HighlightedText: React.FC<HighlightedTextProps> = ({ text, highlight = '' }) => {
  const style = useStyles2(getStyles)

  if (!highlight) {
    return <span>{text}</span>
  }

  // Escape special characters in the highlight string
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const escapedHighlight = escapeRegExp(highlight)
  const regex = new RegExp(`(${escapedHighlight})`, 'gi')
  const parts = text.split(regex)

  return (
    <span>
      {parts.map((part: string, index: number) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className={style.hilightText} data-testid="highlighted-text-span">
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </span>
  )
}

export default HighlightedText
