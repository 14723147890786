import React, { ReactNode } from 'react'
import { useStyles2 } from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'

interface SpaceProps {
  children: ReactNode
  customStyles?: string
  spacing?: Partial<SpacingProps>
}

interface SpacingProps {
  mt?: number
  mb?: number
  ml?: number
  mr?: number
  mx?: number
  my?: number
  m?: number
}

const Space: React.FC<SpaceProps> = ({ children, customStyles, spacing }) => {
  const getStyles = (theme: GrafanaTheme2) => {
    const margin = {
      top: spacing?.mt ?? spacing?.my ?? spacing?.m,
      bottom: spacing?.mb ?? spacing?.my ?? spacing?.m,
      left: spacing?.ml ?? spacing?.mx ?? spacing?.m,
      right: spacing?.mr ?? spacing?.mx ?? spacing?.m,
    }

    return {
      container: css`
        ${margin.top !== undefined && `margin-top: ${theme.spacing(margin.top)};`}
        ${margin.bottom !== undefined && `margin-bottom: ${theme.spacing(margin.bottom)};`}
          ${margin.left !== undefined && `margin-left: ${theme.spacing(margin.left)};`}
          ${margin.right !== undefined && `margin-right: ${theme.spacing(margin.right)};`}
      `,
    }
  }

  const style = useStyles2(getStyles)

  return <div className={css(style.container, customStyles)}>{children}</div>
}

export default Space
