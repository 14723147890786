import React, { useCallback, useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import { useForm } from 'react-hook-form'
import { GrafanaTheme2 } from '@grafana/data'
import { Icon, Input, useStyles2 } from '@grafana/ui'
import { useAppSettingContext } from '../../../contexts/appSettingContext'
import { SingleStoreList } from '../../storeList'
import { FormattedStore, FormattedParentStore } from '../../../interfaces/settingUser'
import { Profile } from '../../../interfaces/profile'

type FormValues = {
  storeIds: number[] | []
  accountNumber: string
  name: string
  status: string
  role: string
  userId: string
}

interface SearchProps {
  stores: FormattedStore[]
  setSettingPage: (page: any) => void
  userProfile: Profile | null
  handleAddStore?: (parentStore: FormattedParentStore) => void
  addStore?: boolean
}

const SearchStorePage: React.FC<SearchProps> = ({ stores, setSettingPage, userProfile, handleAddStore, addStore }) => {
  const style = useStyles2(getStyles)
  const { t3StoreApiConfig } = useAppSettingContext()

  const effectRan = useRef(false)

  const [filteredStores, setFilteredStores] = useState<FormattedStore[]>(stores)
  const [highlightText, setHighlightText] = useState<string>('')

  const { control, getValues, setValue, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      storeIds: [],
      accountNumber: '',
      name: '',
      status: '',
      userId: '',
    },
  })

  const queryUserData = useCallback(async () => {
    const formValues = getValues()
    // console.log('formValues', formValues)
  }, [t3StoreApiConfig, getValues])

  const handleSearchStore = (e: React.FormEvent<HTMLInputElement>) => {
    const inputText = (e.target as HTMLInputElement).value
    if (inputText === '') {
      setFilteredStores(stores)
    } else {
      const result = stores
        .map((store) => ({
          store_id: store.store_id,
          store_name: store.store_name,
          branchs: store.branchs.filter(
            (st) =>
              st.store_name.toLowerCase().includes(inputText.toLowerCase()) ||
              st.store_id.toString().includes(inputText)
          ),
        }))
        .filter((store) => store.store_name.length > 0)

      setFilteredStores(result)
    }
    setHighlightText(inputText)
  }

  const onSubmit = useCallback(() => {
    queryUserData()
  }, [queryUserData])

  useEffect(() => {
    if (!effectRan.current) {
      queryUserData()
      effectRan.current = true
    }
  }, [queryUserData])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'storeIds') {
        onSubmit()
      }
    })
    return () => subscription.unsubscribe()
  }, [onSubmit, watch])

  return (
    <section className={style.sidebarContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.space}>
          <Input placeholder="input store name..." prefix={<Icon name="search" />} onChange={handleSearchStore} />
        </div>
        {filteredStores?.length > 0 && (
          <SingleStoreList
            data={filteredStores}
            control={control}
            name="storeIds"
            searchingText={highlightText}
            setDefaultValue={setValue}
            handleAddStore={handleAddStore}
            addStore={addStore}
          />
        )}
      </form>
    </section>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  sidebarContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    overflow: hidden;
    padding: ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(8)} ${theme.spacing(1)};
  `,
  space: css`
    margin-bottom: ${theme.spacing(2)};
  `,
})

export default SearchStorePage
