import React, { useEffect, useState } from 'react'
import {
  useStyles2,
  Text,
  Box,
  InlineFieldRow,
  InputControl,
  Input,
  Button,
  LoadingBar,
  LoadingPlaceholder,
} from '@grafana/ui'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import AgeGroupInfo from './ageGroupInfo'
import Popup, { usePopup } from '../../../components/popup'
import ButtonSetting from '../../dwelltime/button'
import { MODAL_TYPE, MODAL_TYPE_NAME, getModalMessage } from '../../../utils/utils.modalType'
import { validateAgeGroup } from '../../../utils/utils.peoplecountingSetting'
import { getStylesFormInterval } from '../../../styles/styles'
import Space from '../../space'
import { css } from '@emotion/css'
import { getQueries } from '../../../apis/queriesApi'
import { mapQueriesValue } from '../../../utils/utils.mapValue'
import { useAppSettingContext } from '../../../contexts/appSettingContext'
import useQuery from '../../../hooks/useQuery'
import { updatePeopleCountingSetting } from '../../../apis/peoplecountingApi'
import { PeopleCountingSetting } from '../../../interfaces/peopleCounting'

interface FormValues {
  ageGroup: PeopleCountingSetting[]
}

const DEFAULT_NUMBER = 19

const VisitorsAgeGroup = () => {
  const { popupState, displayPopup, hidePopup } = usePopup()
  const [modalType, setModalType] = useState<string | null>(null)
  const { t3StoreApiConfig } = useAppSettingContext()
  const [isLoading, setLoading] = useState(false)

  const visitorAgeGroupData = useQuery({
    queryApi: async () => {
      const resGetPeopleCountingSetting: any = await getQueries({ q: 'iot.func_get_people_counting' }, t3StoreApiConfig)
      const data = resGetPeopleCountingSetting?.result?.data

      if (resGetPeopleCountingSetting?.result?.code !== 10001) {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
          message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
        })
        setModalType(MODAL_TYPE_NAME.ERROR)
      }

      return data ? mapQueriesValue<PeopleCountingSetting>(data, 'interval_id') : null
    },
  })

  const style = useStyles2(getStylesFormInterval)
  const styleBox = useStyles2((theme) => ({
    boxContainer: css`
      ${theme.breakpoints.up('sm')} {
        display: flex;
        justify-items: center;
        align-items: center;
        gap: ${theme.spacing(1)};
      }
      ${theme.breakpoints.down('sm')} {
        display: grid;
        align-items: center;
        gap: ${theme.spacing(0.5)};
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: ${theme.spacing(0.5)};
      }
    `,
  }))

  const form = useForm<FormValues>({
    defaultValues: {
      ageGroup: visitorAgeGroupData?.queryData || undefined,
    },
  })

  const { control, handleSubmit, setValue, getValues, register } = form

  const { fields, remove } = useFieldArray({
    control,
    name: 'ageGroup',
  })

  const onSubmit = (data: FormValues) => {
    if (data?.ageGroup?.length) {
      const isValid = validateAgeGroup(data.ageGroup)

      if (!isValid) {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
          message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
        })
        setModalType(MODAL_TYPE_NAME.API_ERROR)
      } else {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.CONFIRM].title,
          message: getModalMessage(MODAL_TYPE_NAME.CONFIRM),
        })
        setModalType(MODAL_TYPE_NAME.CONFIRM)
      }
    }
  }

  const resetForm = () => {
    form.reset({ ageGroup: visitorAgeGroupData?.queryData || [] })
  }

  const handleClickAdd = () => {
    const currentIntervals = getValues('ageGroup')
    const lastInterval = currentIntervals[currentIntervals.length - 1]?.less_than || 0
    const lastMaxAge = lastInterval ? Number(lastInterval) : null

    if (currentIntervals.length === 0) {
      const initValue = [{ greater_than_or_equal_to: 1, less_than: DEFAULT_NUMBER + 1, interval_id: 1 }]
      setValue('ageGroup', initValue)
    } else {
      if (lastMaxAge && typeof lastMaxAge === 'number') {
        const newMinAge = lastMaxAge + 1
        const newMaxAge = newMinAge + DEFAULT_NUMBER

        const newIntervals = [
          ...currentIntervals,
          {
            greater_than_or_equal_to: newMinAge,
            less_than: newMaxAge,
          },
        ]
        setValue('ageGroup', newIntervals)
      }
    }
  }

  const handleClickConfirmButton = () => {
    updateVisitorAgeSetting()
    hidePopup()
  }

  const updateVisitorAgeSetting = async () => {
    try {
      setLoading(true)
      const data = getValues('ageGroup')
      const newData: PeopleCountingSetting[] = data?.map((interval, index) => ({ ...interval, interval_id: index + 1 }))
      const resUpdateSettingApi = await updatePeopleCountingSetting(newData, t3StoreApiConfig)

      if (resUpdateSettingApi?.result?.code !== 10001) {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
          message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
        })
        setModalType(MODAL_TYPE_NAME.API_ERROR)
      } else {
        visitorAgeGroupData.reload()
      }
    } catch (error) {
      console.log('Error updateVisitorAgeSetting :', error)
    } finally {
      setLoading(false)
    }
  }

  const displayModal = () => {
    switch (modalType) {
      case MODAL_TYPE_NAME.CONFIRM:
        return (
          <Popup {...popupState} okText="OK" onOk={handleClickConfirmButton} cancelText="Cancel" onCancel={hidePopup} />
        )
      case MODAL_TYPE_NAME.API_ERROR:
        return <Popup {...popupState} okText="OK" onOk={hidePopup} />
      default:
        return null
    }
  }

  useEffect(() => {
    if (visitorAgeGroupData?.queryData) {
      form.reset({ ageGroup: visitorAgeGroupData.queryData })
    }
  }, [visitorAgeGroupData?.queryData])

  return (
    <div>
      {visitorAgeGroupData?.isLoading && <LoadingBar width={128} />}
      <AgeGroupInfo data={visitorAgeGroupData?.queryData} />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box gap={1}>
            {fields?.map((field, index) => {
              return (
                <Space key={field.id} spacing={{ mb: 1 }} customStyles={styleBox.boxContainer}>
                  <Text>Interval: {index + 1}: Greater than or equal to </Text>
                  <InlineFieldRow>
                    <InputControl
                      control={control}
                      name={`ageGroup.${index}.greater_than_or_equal_to`}
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={Number(field.value) || undefined}
                          type="number"
                          onKeyDown={(e) => {
                            // Prevent input of e, E, -, +, . and ,
                            if (['e', 'E', '+', '-', '.', ','].includes(e.key)) {
                              e.preventDefault()
                            }
                          }}
                          {...register(`ageGroup.${index}.greater_than_or_equal_to`, { valueAsNumber: true })}
                        />
                      )}
                    />
                  </InlineFieldRow>
                  <Text> less than or equal to </Text>
                  <InlineFieldRow>
                    <InputControl
                      control={control}
                      name={`ageGroup.${index}.less_than`}
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={Number(field.value) || undefined}
                          type="number"
                          onKeyDown={(e) => {
                            // Prevent input of e, E, -, +, . and ,
                            if (['e', 'E', '+', '-', '.', ','].includes(e.key)) {
                              e.preventDefault()
                            }
                          }}
                          {...register(`ageGroup.${index}.less_than`, { valueAsNumber: true })}
                        />
                      )}
                    />
                  </InlineFieldRow>
                  <ButtonSetting
                    type="button"
                    fill="text"
                    onClick={() => remove(index)}
                    customStyles={style.deleteButton}
                  >
                    Delete Interval
                  </ButtonSetting>
                </Space>
              )
            })}
            {fields.length < 10 && (
              <Button type="button" fill="text" onClick={handleClickAdd}>
                + Add Interval
              </Button>
            )}
          </Box>

          <Box gap={1} display="flex" justifyContent="flex-end">
            <Button variant="secondary" type="button" onClick={() => resetForm()}>
              Reset
            </Button>
            <Button type="submit" disabled={isLoading || visitorAgeGroupData?.queryData === null}>
              Confirm
              {isLoading && <LoadingPlaceholder text="" style={{ marginLeft: '16px', marginTop: '30px' }} />}
            </Button>
          </Box>
        </form>
      </FormProvider>
      {displayModal()}
    </div>
  )
}

export default VisitorsAgeGroup
