import React, { useEffect, useState, Fragment } from 'react'
import { css } from '@emotion/css'
import { useForm } from 'react-hook-form'
import { SelectOptions } from '../../interfaces/form'
import { FormattedStore, EditUserState } from '../../interfaces/settingUser'
import { GrafanaTheme2 } from '@grafana/data'
import {
  Button,
  Icon,
  InlineField,
  InlineFieldRow,
  Input,
  InputControl,
  Select,
  Text,
  useStyles2,
  LoadingPlaceholder,
  IconButton,
  Tooltip,
  LoadingBar,
} from '@grafana/ui'
import { useAppSettingContext } from '../../contexts/appSettingContext'
import { MultipleStoreList } from '../storeList'
import password from 'secure-random-password'
import Popup, { usePopup } from '../../components/popup'
import { MODAL_TYPE, MODAL_TYPE_NAME, getModalMessage } from '../../utils/utils.modalType'
import { Profile } from '../../interfaces/profile'
import { addUserApi, updateUserApi, getUserByIdApi } from '../../apis/userApi'
import { SettingPageEnum } from '../../pages/user/setting'

type FormValues = {
  email: string
  user_name: string
  user_login: string
  password: string
  role: number | string
  create_by: string
  store_ids: number[] | []
  status: string
}

interface AddProps {
  stores: FormattedStore[]
  roles: SelectOptions[]
  setSettingPage: (page: any) => void
  userProfile: Profile | null
  editUserState: EditUserState
}

const generateRandomPassword = () => {
  return password.randomPassword({
    length: 8,
    characters: [
      { characters: password.lower },
      { characters: password.digits },
      { characters: password.upper, exactly: 1 },
      { characters: password.symbols, exactly: 1 },
    ],
  })
}

const AddUserPage: React.FC<AddProps> = ({ stores = [], roles, setSettingPage, userProfile, editUserState }) => {
  const style = useStyles2(getStyles)
  const { t3StoreApiConfig } = useAppSettingContext()
  const [filteredStores, setFilteredStores] = useState<FormattedStore[]>(stores)
  const [roleSelected, setRoleSelected] = useState<string | number | undefined>('')
  const [highlightText, setHighlightText] = useState<string>('')
  const [isLoading, setLoading] = useState(false)
  const { popupState, displayPopup, hidePopup } = usePopup()
  const [modalType, setModalType] = useState<string | null>(null)

  const { control, getValues, setValue, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      email: '',
      user_name: '',
      user_login: '',
      password: '',
      role: '',
      create_by: '',
      store_ids: [],
      status: '',
    },
  })

  useEffect(() => {
    const fetchStoreIds = async () => {
      const userByIdData = await getUserByIdApi(Number(editUserState?.user?.user_id), t3StoreApiConfig)
      const userStoreIds = userByIdData?.result?.store_ids

      const allBranchIds = stores.map((f) => ({
        mainId: f.store_id,
        branchIds: f.branchs.map((branch: any) => branch.store_id),
      }))

      const formattedUserStoreIds = allBranchIds.map((store) => {
        const someBranchsNotInclude = store.branchIds.some((id) => !userStoreIds?.includes(id))
        return !someBranchsNotInclude ? [store.mainId, ...(userStoreIds ?? [])] : userStoreIds
      })

      const newFormattedUserStoreIds = formattedUserStoreIds.flat().filter((id): id is number => id !== undefined)

      setValue('store_ids', newFormattedUserStoreIds)
    }

    if (editUserState.isEditUser) {
      setValue('email', editUserState?.user?.email || '')
      setValue('user_name', editUserState?.user?.user_name || '')
      setValue('user_login', editUserState?.user?.user_login || '')
      setValue('role', editUserState?.user?.role_id || '')
      setValue('create_by', editUserState?.user?.create_by || '')
      setRoleSelected(editUserState?.user?.role_id)
      fetchStoreIds()
    }
  }, [editUserState.isEditUser, setValue])

  const handleSearchStore = (e: React.FormEvent<HTMLInputElement>) => {
    const inputText = (e.target as HTMLInputElement).value
    if (inputText === '') {
      setFilteredStores(stores)
    } else {
      const result = stores
        .map((store) => ({
          store_id: store.store_id,
          store_name: store.store_name,
          branchs: store.branchs.filter(
            (st) =>
              st.store_name.toLowerCase().includes(inputText.toLowerCase()) ||
              st.store_id.toString().includes(inputText)
          ),
        }))
        .filter((store) => store.store_name.length > 0)

      setFilteredStores(result)
    }
    setHighlightText(inputText)
  }

  const filterParentIdStores = (store_ids: number[]): number[] => {
    const storesParentIds = new Set(filteredStores.map((item) => item.store_id))

    return store_ids.filter((id: number) => id !== null && !isNaN(id) && !storesParentIds.has(id))
  }

  const onSubmit = (data: FormValues) => {
    const { store_ids } = data

    if (Array.isArray(store_ids) && store_ids?.length === 0) {
      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.FIELDS_REQUIRE].title,
        message: getModalMessage(MODAL_TYPE_NAME.FIELDS_REQUIRE),
      })
      setModalType(MODAL_TYPE_NAME.FIELDS_REQUIRE)
    } else {
      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.CONFIRM].title,
        message: getModalMessage(MODAL_TYPE_NAME.CONFIRM, 'user'),
      })
      setModalType(MODAL_TYPE_NAME.CONFIRM)
    }
  }

  const handleGeneratePassword = () => {
    const randomPassword = generateRandomPassword()
    setValue('password', randomPassword)
  }

  const onError = (errors: any) => {
    displayPopup({
      title: MODAL_TYPE[MODAL_TYPE_NAME.FIELDS_REQUIRE].title,
      message: getModalMessage(MODAL_TYPE_NAME.FIELDS_REQUIRE),
    })
    setModalType(MODAL_TYPE_NAME.FIELDS_REQUIRE)
  }

  const handleClickConfirmButton = () => {
    if (editUserState.isEditUser) {
      updateUser()
    } else {
      addUser()
    }

    hidePopup()
  }

  const addUser = async () => {
    const { email, password, role, store_ids, user_login, user_name } = getValues()
    const usersParams = {
      email,
      password,
      role_id: role,
      status: true,
      store_ids: filterParentIdStores(store_ids),
      user_login,
      user_name,
      create_by: userProfile?.name || userProfile?.login || userProfile?.email || '',
    }

    try {
      setLoading(true)
      const resultAddUser = await addUserApi(usersParams, t3StoreApiConfig)

      if (resultAddUser?.result?.code !== 10001) {
        const errorMessage = resultAddUser?.error?.data?.error?.message
          ? resultAddUser?.error?.data?.error?.message
          : MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].message

        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR_MESSAGE].title,
          message: getModalMessage(MODAL_TYPE_NAME.API_ERROR_MESSAGE, errorMessage),
        })
        setModalType(MODAL_TYPE_NAME.API_ERROR_MESSAGE)
      } else {
        setSettingPage(SettingPageEnum.SEARCH)
      }
    } catch (error: any) {
      console.log('Error addUser :', error)

      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR_MESSAGE].title,
        message: getModalMessage(MODAL_TYPE_NAME.API_ERROR_MESSAGE, MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].message),
      })
      setModalType(MODAL_TYPE_NAME.API_ERROR_MESSAGE)
    } finally {
      setLoading(false)
    }
  }

  const updateUser = async () => {
    const { email, role, store_ids, user_login, user_name } = getValues()
    const usersParams = {
      email,
      role_id: Number(role),
      store_ids: filterParentIdStores(store_ids),
      user_login,
      user_name,
      update_by: userProfile?.name || userProfile?.login || userProfile?.email || 'Anonymous',
    }

    try {
      setLoading(true)

      const user_id = Number(editUserState?.user?.user_id)
      const resultAddUser = await updateUserApi(user_id, usersParams, t3StoreApiConfig)

      if (resultAddUser?.result?.code !== 10001) {
        const errorMessage = resultAddUser?.error?.data?.error?.message
          ? resultAddUser?.error?.data?.error?.message
          : MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].message

        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR_MESSAGE].title,
          message: getModalMessage(MODAL_TYPE_NAME.API_ERROR_MESSAGE, errorMessage),
        })
        setModalType(MODAL_TYPE_NAME.API_ERROR_MESSAGE)
      } else {
        setSettingPage(SettingPageEnum.SEARCH)
      }
    } catch (error: any) {
      console.log('Error updateUser :', error)

      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR_MESSAGE].title,
        message: getModalMessage(MODAL_TYPE_NAME.API_ERROR_MESSAGE, MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].message),
      })
      setModalType(MODAL_TYPE_NAME.API_ERROR_MESSAGE)
    } finally {
      setLoading(false)
    }
  }

  const displayModal = () => {
    switch (modalType) {
      case MODAL_TYPE_NAME.CONFIRM:
        return (
          <Popup {...popupState} okText="OK" onOk={handleClickConfirmButton} cancelText="Cancel" onCancel={hidePopup} />
        )
      case MODAL_TYPE_NAME.FIELDS_REQUIRE:
        return <Popup {...popupState} okText="OK" onOk={hidePopup} />
      case MODAL_TYPE_NAME.API_ERROR_MESSAGE:
        return <Popup {...popupState} okText="OK" onOk={hidePopup} />
      default:
        return null
    }
  }

  return (
    <div className={style.pageWrapper}>
      {isLoading && <LoadingBar width={128} />}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className={style.spaceText1}>
          <Text element="h4">Basic Information</Text>
        </div>
        <div className={style.wrapperBox}>
          <InlineFieldRow className={style.wrapperBox}>
            <span className={style.requireField}>*</span>
            <InlineField label="Role:" transparent>
              <InputControl
                render={({ field }) => (
                  <Select
                    {...field}
                    options={roles}
                    onChange={(e) => {
                      field.onChange(e.value)
                      setRoleSelected(e.value)
                    }}
                    value={roleSelected}
                  />
                )}
                control={control}
                name="role"
                rules={{ required: 'Role is required' }}
              />
            </InlineField>
            <IconButton
              tooltip="After the role is modified, the business permission of the account will inherit the business permission of the new parent role. This may result in the unavailability of the currently operable functions of the account."
              variant="secondary"
              name="question-circle"
            />
          </InlineFieldRow>
          <InlineFieldRow>
            <div className={style.inputBox}>
              <div className={style.boxUser}>
                <div className={style.box}>
                  <span className={style.requireField}>*</span>
                  <InlineField label="Login Account:" transparent>
                    <InputControl
                      render={({ field }) => (
                        <Tooltip content="Please input 1-20 characters">
                          <Input {...field} placeholder="Please input 1-20 characters" />
                        </Tooltip>
                      )}
                      control={control}
                      name="user_login"
                      rules={{
                        required: 'Login Account is required',
                        minLength: { value: 1, message: 'User Name must be at least 1 characters long' },
                        maxLength: { value: 20, message: 'User Name must be no more than 20 characters long' },
                      }}
                    />
                  </InlineField>
                </div>
                <div className={style.box}>
                  <span className={style.requireField}> *</span>
                  <InlineField label="User Name:" transparent>
                    <InputControl
                      render={({ field }) => (
                        <Tooltip content="Please input 1-40 characters">
                          <Input {...field} placeholder="Please input 1-40 characters" />
                        </Tooltip>
                      )}
                      control={control}
                      name="user_name"
                      rules={{
                        required: 'User Name is required',
                        minLength: { value: 1, message: 'User Name must be at least 1 characters long' },
                        maxLength: { value: 40, message: 'User Name must be no more than 40 characters long' },
                      }}
                    />
                  </InlineField>
                </div>
              </div>
              {!editUserState.isEditUser ? (
                <div className={style.boxPassword}>
                  <InlineFieldRow>
                    <span className={style.requireField}> *</span>
                    <InlineField label="Login Password:" transparent>
                      <InputControl
                        render={({ field }) => (
                          <Tooltip content="6-16 digits, including letters, at least one (uppercase letter + numbers + special character)">
                            <Input
                              {...field}
                              placeholder="6-16 digits, including letters, at least one (uppercase letter + numbers + special character)"
                            />
                          </Tooltip>
                        )}
                        control={control}
                        name="password"
                        rules={{
                          required: 'Password is required',
                          minLength: { value: 6, message: 'Password must be at least 6 characters long' },
                          maxLength: { value: 16, message: 'Password must be no more than 16 characters long' },
                          validate: {
                            hasSpecialChar: (value) =>
                              /[!@#$%^&*()_+~`|}{[\]:;?><,./\-=\]]/.test(value) ||
                              'Password must contain at least one special character',
                            hasUpperCase: (value) =>
                              /[A-Z]/.test(value) || 'Password must contain at least one uppercase letter',
                            hasNumber: (value) => /\d/.test(value) || 'Password must contain at least one number',
                          },
                        }}
                      />
                    </InlineField>
                  </InlineFieldRow>
                  <div>
                    <InlineField transparent>
                      <Button fill="outline" variant="destructive" onClick={handleGeneratePassword}>
                        Random Password
                      </Button>
                    </InlineField>
                  </div>
                </div>
              ) : (
                <Fragment />
              )}
            </div>
          </InlineFieldRow>
          <InlineFieldRow className={style.wrapperBox}>
            <span className={style.requireField}>*</span>
            <InlineField label="E-Mail:" transparent>
              <InputControl
                render={({ field }) => <Input {...field} placeholder="Please input email" />}
                control={control}
                name="email"
                rules={{
                  required: 'Email is required',
                }}
              />
            </InlineField>
          </InlineFieldRow>
        </div>
        <div className={style.mainContainer}>
          <section className={style.sidebarContainer}>
            <div className={style.spaceText2}>
              <Text element="h4">
                <span className={style.requireField}>* </span>Select store permissions
              </Text>
            </div>
            <div>
              <Input placeholder="input store name..." prefix={<Icon name="search" />} onChange={handleSearchStore} />
            </div>
            <div className={style.wrapperFilter}>
              {filteredStores?.length > 0 && (
                <MultipleStoreList
                  data={filteredStores}
                  control={control}
                  name="store_ids"
                  searchingText={highlightText}
                  setDefaultValue={!editUserState.isEditUser && setValue}
                />
              )}
            </div>
          </section>
        </div>
        <div className={style.box}>
          <Button type="submit" disabled={isLoading} className={style.button}>
            Confirm
            {isLoading && <LoadingPlaceholder text="" style={{ marginLeft: '16px', marginTop: '30px' }} />}
          </Button>
          <Button variant="secondary" type="button" onClick={() => setSettingPage(SettingPageEnum.SEARCH)}>
            Cancel
          </Button>
        </div>
      </form>
      {displayModal()}
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  pageWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(6)};
    height: 100%;
  `,
  mainContainer: css`
    display: grid;
    grid-template-columns: minmax(378px, auto) 1fr;
    gap: ${theme.spacing(4)};
  `,
  sidebarContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    overflow: hidden;
    padding: ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(0.5)};
  `,
  dataContainer: css`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    gap: 3rem;
    overflow: auto;
  `,
  filterSection: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(1)};
  `,
  tableHeader: css`
    display: flex;
    justify-content: space-between;
  `,
  buttonGroup: css`
    display: flex;
    gap: ${theme.spacing(2)};
  `,
  box: css`
    display: flex;
    margin-bottom: ${theme.spacing(2)};
    margin-right: ${theme.spacing(2)};

    ${theme.breakpoints.up('lg')} {
      margin-right: ${theme.spacing(1)};
    }

    @media (min-width: 1441px) {
      margin-right: ${theme.spacing(4)};
    }
  `,
  requireField: css`
    color: ${theme.colors.error.text};
  `,
  spaceText1: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  spaceText2: css`
    margin-top: ${theme.spacing(2)};
  `,
  inputBox: css`
    display: flex;
    width: 100%;
    flex-direction: column;

    ${theme.breakpoints.up('xxl')} {
      flex-direction: row;
    }
  `,
  wrapperBox: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  wrapperFilter: css`
    min-height: 50vh;
  `,
  boxPassword: css`
    display: flex;
    gap: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
    flex-direction: column;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
    }
  `,
  boxUser: css`
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.up('lg')} {
      flex-direction: row;
    }
  `,
  button: css`
    margin-right: ${theme.spacing(2)};
  `,
})

export default AddUserPage
