import React, { useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/css'
import { useForm } from 'react-hook-form'
import { GrafanaTheme2 } from '@grafana/data'
import {
  Button,
  InlineField,
  InlineFieldRow,
  Input,
  InputControl,
  Select,
  useStyles2,
  Switch,
  FileUpload,
  IconButton,
  Modal,
  InteractiveTable,
  CellProps,
  Column,
  LoadingPlaceholder,
} from '@grafana/ui'
import { getQueries } from '../../../apis/queriesApi'
import { useAppSettingContext } from '../../../contexts/appSettingContext'
import Popup, { usePopup } from '../../popup'
import { mapSelectInputData } from '../../../utils/utils.mapValue'
import { MODAL_TYPE, MODAL_TYPE_NAME, getModalMessage } from '../../../utils/utils.modalType'
import { FormattedParentStore, Store } from '../../../interfaces/settingUser'
import { SettingPageEnum } from '../../../pages/user/setting'
import { Profile } from '../../../interfaces/profile'
import { addStoreImages, addStoreHeatmapApi, addStoreHeatmapPictureApi } from '../../../apis/heatmapApi'
import { mapQueriesValue } from '../../../utils/utils.mapValue'
import { StoreHeatmapPicture } from '../../../interfaces/heatmap'

type FormValues = {
  parentStore: string
  storeId: string
  storeName: string
  area: string
  province: string
  region: string
  district: string
  mallType: string
  status: boolean
  floor: string
  floorImages: FileList
}

interface FormStoreSettingProps {
  setSettingPage: (page: any) => void
  userProfile: Profile | null
  setEditUserState?: () => void
  parentStoreData: FormattedParentStore | null
  setAddStoreState: (state: boolean) => void
}

interface ImageRow {
  floor: string
  name: string
  file: File
  index: number
}

const FormStoreSetting: React.FC<FormStoreSettingProps> = ({
  setSettingPage,
  userProfile,
  setEditUserState,
  parentStoreData,
  setAddStoreState,
}) => {
  const style = useStyles2(getStyles)
  const { t3StoreApiConfig } = useAppSettingContext()
  const { popupState, displayPopup, hidePopup } = usePopup()

  const [modalType, setModalType] = useState<string | null>(null)
  const [provices, setProvinces] = useState<{ label: string; value: string }[] | undefined>()
  const [regions, setRegions] = useState<{ label: string; value: string }[] | undefined>()
  const [area, setArea] = useState<{ label: string; value: string }[] | undefined>()
  const [mallType, setMallType] = useState<{ label: string; value: string }[] | undefined>()
  const [floorImages, setFloorImages] = useState<File[]>([])
  const [latestImage, setLatestImage] = useState<string | null>(null)
  const [modalImage, setModalImage] = useState<string | null>(null)
  const [isLoading, setLoading] = useState(false)

  const { control, getValues, setValue, handleSubmit, watch, reset } = useForm<FormValues>({
    defaultValues: {
      parentStore: parentStoreData?.storeName,
      storeId: '',
      storeName: '',
      region: '',
      area: '',
      district: '',
      province: '',
      mallType: '',
      status: true,
      floor: '',
      floorImages: undefined,
    },
  })

  const watchFloor = watch('floor')
  const watchId = watch('storeId')
  const [isDisabledUpload, setIsDisabledUpload] = useState(true)

  useEffect(() => {
    const parentStoreName = parentStoreData?.storeName
    if (parentStoreName) {
      setValue('parentStore', parentStoreName)
    }
  }, [setValue, parentStoreData])

  const queryStoreData = useCallback(async () => {
    const queries = [
      {
        query: 'iot.func_get_provinces',
        field: { label: 'province', value: 'province' },
        setter: setProvinces,
      },
      {
        query: 'iot.func_get_regions',
        field: { label: 'region', value: 'region' },
        setter: setRegions,
      },
      { query: 'iot.func_get_areas', field: { label: 'area', value: 'area' }, setter: setArea },
      {
        query: 'iot.func_get_mall_types',
        field: { label: 'mall_type', value: 'mall_type' },
        setter: setMallType,
      },
    ]

    const promises = queries.map(({ query, field, setter }) =>
      getQueries({ q: query }, t3StoreApiConfig).then(({ result }) => {
        const selectData = mapSelectInputData(result?.data, field)
        setter(selectData)
      })
    )

    await Promise.all(promises)
  }, [t3StoreApiConfig])

  const handleConfirm = async () => {
    hidePopup()

    addStoreSetting()
  }

  const addStoreSetting = async () => {
    const create_by = userProfile?.name || userProfile?.login || userProfile?.email || 'Anonymous'
    const storeId = parseInt(getValues().storeId, 10)
    const storesData = await checkStoreId(storeId)

    if (storesData?.length > 0) {
      setModalType(MODAL_TYPE_NAME.API_ERROR_MESSAGE)
      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR_MESSAGE].title,
        message: 'The provided store ID already exists. Please use a unique store ID.',
      })
      return
    }

    if (floorImages?.length) {
      const storeHeatmapPictureParams = await uploadImages(storeId, create_by, floorImages)
      if (!storeHeatmapPictureParams.length) return

      const { error: ErrorResultHeatmapPic } = await addStoreHeatmapPictureApi(
        storeHeatmapPictureParams,
        t3StoreApiConfig
      )

      if (ErrorResultHeatmapPic) {
        setModalType(MODAL_TYPE_NAME.API_ERROR)
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
          message: `${MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].message}`,
        })
        return
      }
    }

    await addStoreHeatmap()
  }

  const addStoreHeatmap = async () => {
    try {
      setLoading(true)
      const { storeId, storeName, region, area, district, province, mallType, status } = getValues()
      const create_by = userProfile?.name || userProfile?.login || userProfile?.email || 'Anonymous'

      const params = [
        {
          store_id: parseInt(storeId, 10),
          store_name: storeName,
          store_region: region,
          area,
          district,
          province,
          mall_type: mallType,
          status: status ? 'enable' : 'disable',
          store_parent_id: parentStoreData?.storeId,
          create_by,
          update_by: create_by,
          store_type: 'branch',
        },
      ]

      const { error } = await addStoreHeatmapApi(params, t3StoreApiConfig)
      if (error) {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
          message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
        })
        setModalType(MODAL_TYPE_NAME.API_ERROR)
      } else {
        setAddStoreState(false)
        reset()
      }
    } catch (error) {
      console.log('Error addStoreHeatmap :', error)
    } finally {
      setLoading(false)
    }
  }

  const checkStoreId = async (storeId: number) => {
    const { result: storesResult } = await getQueries(
      { q: 'iot.func_get_store_by_id', param_input: storeId },
      t3StoreApiConfig
    )
    return mapQueriesValue<Store>(storesResult?.data)
  }

  const uploadImages = async (storeIdVal: number, create_by: string, floorImages: File[]) => {
    const formData = new FormData()
    let storeHeatmapPictureParams: StoreHeatmapPicture[] = []

    floorImages.forEach((file, index) => {
      const [storeId, floor] = file.name.split('_')
      if (storeIdVal !== parseInt(storeId, 10)) {
        storeIdVal = parseInt(storeId, 10)
      }
      formData.append((index + 1).toString(), file)
      storeHeatmapPictureParams.push({
        store_id: storeIdVal,
        floor,
        picture_name: file.name,
        path: `${t3StoreApiConfig.apiUrl}/${file.name}`,
        create_by,
        update_by: create_by,
      })
    })

    const { result: resultStoreImages, error: errorStoreImages } = await addStoreImages(
      storeIdVal,
      formData,
      t3StoreApiConfig
    )

    if (resultStoreImages?.data?.failed_list?.length) {
      const fileError = resultStoreImages.data.failed_list.join(', ')
      storeHeatmapPictureParams = storeHeatmapPictureParams.filter((data) => {
        return !resultStoreImages.data.failed_list.includes(data.picture_name)
      })

      setModalType(MODAL_TYPE_NAME.API_ERROR_MESSAGE)
      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR_MESSAGE].title,
        message: `Sorry, Can't upload ${fileError}`,
      })
    }

    if (errorStoreImages) {
      const errMsg = errorStoreImages?.message || MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].message
      setModalType(MODAL_TYPE_NAME.API_ERROR_MESSAGE)
      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR_MESSAGE].title,
        message: `${errMsg}`,
      })
      storeHeatmapPictureParams = []
    }

    return storeHeatmapPictureParams
  }

  const onSubmit = useCallback(async () => {
    setModalType(MODAL_TYPE_NAME.CONFIRM)
    displayPopup({
      title: MODAL_TYPE[MODAL_TYPE_NAME.CONFIRM].title,
      message: getModalMessage(MODAL_TYPE_NAME.CONFIRM, 'store setting'),
    })
  }, [getValues, floorImages, userProfile])

  const handleClickAddUser = () => {
    setSettingPage(SettingPageEnum.ADD)
  }

  const onChangeUserStatus = (checked: boolean) => {
    setValue('status', checked)
  }

  const handleImageChange = (files: FileList | null) => {
    if (files) {
      const validFiles = Array.from(files).filter((file) =>
        ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
      )

      if (validFiles?.length > 0) {
        const latestFile = validFiles[validFiles.length - 1]
        setLatestImage(URL.createObjectURL(latestFile))

        const floor = getValues().floor
        const storeId = getValues().storeId

        const modifiedFiles = validFiles.map((file) => {
          const newFloor = floor.replace(/\s+/g, '_')
          const newFileName = `${storeId}_${newFloor}_${file.name}`
          return new File([file], newFileName, { type: file.type })
        })

        setFloorImages((prevFiles) => {
          const filteredFiles = prevFiles.filter((file) => {
            const [fileStoreId] = file.name.split('_')
            return fileStoreId === storeId && !file.name.startsWith(`${storeId}_${floor}_`)
          })

          return [...filteredFiles, ...modifiedFiles]
        })
        setValue('floorImages', files)
      }
    }
  }

  const handleImageRemove = (index: number) => {
    setFloorImages((prevFiles) => prevFiles.filter((_, i) => i !== index))
  }

  useEffect(() => {
    queryStoreData()
  }, [])

  useEffect(() => {
    if (watchFloor && watchId) {
      setIsDisabledUpload(false)
    } else {
      setIsDisabledUpload(true)
    }
  }, [watchFloor, watchId])

  useEffect(() => {
    if (floorImages?.length) {
      setFloorImages([])
    }
  }, [watchId])

  const modalFactory = () => {
    switch (modalType) {
      case MODAL_TYPE_NAME.CONFIRM:
        return <Popup {...popupState} onOk={handleConfirm} okText="OK" cancelText="Cancel" onCancel={hidePopup} />
      case MODAL_TYPE_NAME.API_ERROR:
        return <Popup {...popupState} okText="OK" onOk={hidePopup} />
      case MODAL_TYPE_NAME.API_ERROR_MESSAGE:
        return <Popup {...popupState} okText="OK" onOk={hidePopup} />
      case MODAL_TYPE_NAME.FIELDS_REQUIRE:
        return <Popup {...popupState} okText="OK" onOk={hidePopup} />
      default:
        return null
    }
  }

  const DeleteCell = ({ row: { original } }: CellProps<ImageRow>) => (
    <>
      <IconButton
        name="file-landscape-alt"
        size="xl"
        tooltip={'image preview'}
        variant="secondary"
        onClick={() => setModalImage(URL.createObjectURL(original.file))}
      />
      <IconButton
        name="times"
        size="xl"
        tooltip={'delete'}
        variant="destructive"
        onClick={() => handleImageRemove(original.index)}
      />
    </>
  )

  const columns: Array<Column<ImageRow>> = [
    { id: 'floor', header: 'Floor' },
    { id: 'name', header: 'Name' },
    { id: 'action', header: '', cell: DeleteCell },
  ]

  const rows = floorImages.map((file, i) => {
    const [, floor] = file.name.split('_')
    return {
      index: i,
      floor,
      name: file.name,
      file,
    }
  })

  const handleClickCancel = () => {
    reset()
    setAddStoreState(false)
  }

  return (
    <section className={style.dataContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.filterSection}>
          <InlineFieldRow>
            <InlineField label="Parent Store:" transparent className={style.rowContent}>
              <InputControl
                render={({ field }) => <Input {...field} disabled className={style.input} />}
                control={control}
                name="parentStore"
              />
            </InlineField>
            <InlineField label="Store ID:" transparent>
              <InputControl
                render={({ field }) => <Input {...field} className={style.input} />}
                control={control}
                name="storeId"
              />
            </InlineField>
            <InlineField label="Name:" transparent className={style.rowContent}>
              <InputControl render={({ field }) => <Input {...field} />} control={control} name="storeName" />
            </InlineField>
          </InlineFieldRow>
          <InlineFieldRow>
            <InlineField label="Region:" transparent className={style.rowContent}>
              <InputControl
                render={({ field }) => (
                  <Select
                    {...field}
                    options={regions}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                  />
                )}
                control={control}
                name="region"
              />
            </InlineField>
            <InlineField label="Area:" transparent>
              <InputControl
                render={({ field }) => (
                  <Select
                    {...field}
                    options={area}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                  />
                )}
                control={control}
                name="area"
              />
            </InlineField>
            <InlineField label="District:" transparent>
              <InputControl render={({ field }) => <Input {...field} />} control={control} name="district" />
            </InlineField>
          </InlineFieldRow>
          <InlineFieldRow>
            <InlineField label="Province:" transparent className={style.rowContent}>
              <InputControl
                render={({ field }) => (
                  <Select
                    {...field}
                    options={provices}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                  />
                )}
                control={control}
                name="province"
              />
            </InlineField>
            <InlineField label="Mall Type:" transparent>
              <InputControl
                render={({ field }) => (
                  <Select
                    {...field}
                    options={mallType}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                  />
                )}
                control={control}
                name="mallType"
              />
            </InlineField>
          </InlineFieldRow>
          <InlineFieldRow>
            <InlineField label="Status:" transparent className={style.rowContent}>
              <InputControl
                render={({ field }) => (
                  <Switch
                    value={field.value}
                    onChange={(event) => {
                      const checked = event.currentTarget.checked
                      field.onChange(checked)
                      onChangeUserStatus(checked)
                    }}
                  />
                )}
                control={control}
                name="status"
              />
            </InlineField>
          </InlineFieldRow>
          <InlineFieldRow>
            <InlineField
              label="Floor:"
              transparent
              tooltip="Only one image may be uploaded for each floor."
              className={style.rowContent}
            >
              <InputControl render={({ field }) => <Input {...field} />} control={control} name="floor" />
            </InlineField>
            <div className={isDisabledUpload ? style.disabledUpload : style.enableUpload}>
              <InputControl
                render={({ field }) => (
                  <FileUpload
                    onFileUpload={(e: React.FormEvent<HTMLInputElement>) => {
                      handleImageChange(e.currentTarget?.files)

                      // Access the input element using its data-testid attribute to clear the input value
                      const inputElement = document.querySelector(
                        '[data-testid="data-testid-file-upload-input-field"]'
                      ) as HTMLInputElement
                      if (inputElement) {
                        inputElement.value = '' // Clear the input value to allow re-uploading the same file
                      }
                    }}
                    accept=".jpeg,.jpg,.png"
                  />
                )}
                control={control}
                name="floorImages"
              />
            </div>
          </InlineFieldRow>
          <div>
            {latestImage && floorImages?.length > 0 && (
              <div>
                <img
                  src={latestImage}
                  alt="Latest Upload"
                  style={{ maxWidth: '300px', maxHeight: 'auto', margin: 'auto', display: 'block' }}
                />
              </div>
            )}
          </div>

          <InteractiveTable
            columns={columns as Array<Column<ImageRow>>}
            data={rows}
            getRowId={(row: any) => row.name}
          />

          {modalImage && (
            <Modal title="Image Preview" isOpen={true} onDismiss={() => setModalImage(null)}>
              <img src={modalImage} alt="Preview" style={{ width: '100%' }} />
            </Modal>
          )}
          <div className={style.buttonGroup}>
            <Button
              type="submit"
              onClick={handleClickAddUser}
              disabled={
                !watch('storeId') ||
                !watch('storeName') ||
                !watch('region') ||
                !watch('area') ||
                !watch('district') ||
                !watch('province') ||
                !watch('mallType')
              }
            >
              Confirm
              {isLoading && <LoadingPlaceholder text="" style={{ marginLeft: '16px', marginTop: '30px' }} />}
            </Button>
            <Button type="button" fill="outline" onClick={handleClickCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
      {modalFactory()}
    </section>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  dataContainer: css`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    gap: 3rem;
    overflow: auto;
  `,
  filterSection: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(1)};
  `,
  tableHeader: css`
    display: flex;
    justify-content: space-between;
  `,
  buttonGroup: css`
    display: flex;
    gap: ${theme.spacing(2)};
    margin-left: auto;
    margin-top: ${theme.spacing(2)};
  `,
  disabledUpload: css`
    pointer-events: none;
    opacity: 0.5;
  `,
  enableUpload: css`
    pointer-events: auto;
    opacity: 1;
  `,
  rowContent: css`
    align-items: center !important;
  `,
  input: css`
    max-width: 100px;
  `,
})

export default FormStoreSetting
