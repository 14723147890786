// ex => attachment; filename="DetailReport-20240301-20240426_20240426124205.csv"
const getFileNameFromContentDispositionHeader = (headers: Headers) => {
  const contentDisposition = headers?.get('Content-Disposition')
  return (
    contentDisposition
      ?.split(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)?.[1]
      ?.replace(/"/g, '')
      ?.replace(/'/g, '') || ''
  )
}

export { getFileNameFromContentDispositionHeader }
