import React, { Fragment } from 'react'
import { useStyles2, Text, Box } from '@grafana/ui'

import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'
import { PeopleCountingSetting } from '../../../interfaces/peopleCounting'

interface AgeGroupInfoProps {
  data: PeopleCountingSetting[] | null
}

const AgeGroupInfo: React.FC<AgeGroupInfoProps> = ({ data }) => {
  const style = useStyles2(getStyles)

  return (
    <div className={style.container}>
      <div className={style.space}>
        <Box display="flex">
          <div data-testid="agegroup-info" className={style.info}>
            If user-defined Visitors age is set, you can filter and view the data according to the age groups.
          </div>
        </Box>
      </div>
      <div className={style.itemContainer} data-testid={`agegroup-container`}>
        {data?.length ? (
          data.map((field, index) => (
            <div className={style.item} key={index} data-testid={`agegroup-item-${index}`}>
              <Text>{`Age ${index + 1}: ${
                field.greater_than_or_equal_to && field.less_than && field.greater_than_or_equal_to
              }-${field.less_than}`}</Text>
            </div>
          ))
        ) : (
          <Fragment />
        )}
      </div>
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    itemContainer: css`
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: ${theme.spacing(2)};
      align-items: inherit;
      ${theme.breakpoints.down('md')} {
        grid-template-columns: repeat(4, 1fr);
      }
      ${theme.breakpoints.down('sm')} {
        grid-template-columns: repeat(2, 1fr);
      }
    `,
    item: css`
      border-color: ${theme.colors.border.medium};
      border-style: solid;
      border-radius: 6px;
      padding: ${theme.spacing(1)};
      text-align: center;
    `,
    icon: css`
      width: 20px;
      margin-left: ${theme.spacing(1)};
      color: ${theme.colors.info.main};
    `,
    space: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    info: css`
      background: ${theme.colors.background.canvas};
      padding: ${theme.spacing(1)} ${theme.spacing(2)};
      border-radius: 6px;
    `,
  }
}

export default AgeGroupInfo
