import React, { useState, useEffect } from 'react'
import {
  Button,
  Input,
  InputControl,
  Text,
  InlineFieldRow,
  Field,
  Box,
  useStyles2,
  LoadingPlaceholder,
  LoadingBar,
} from '@grafana/ui'

import { PluginPage } from '@grafana/runtime'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import { SettingContainer, SettingInfo, ButtonSetting } from '../../components/dwelltime'
import Space from '../../components/space'
import Popup, { usePopup } from '../../components/popup'
import { validateIntervals } from '../../utils/utils.dwellSetting'
import { MODAL_TYPE, MODAL_TYPE_NAME, getModalMessage } from '../../utils/utils.modalType'
import { getStylesFormInterval } from '../../styles/styles'
import { updateDwellTimeSettingApi, getDwellTimeSettingApi } from '../../apis/dewellApi'
import { useAppSettingContext } from '../../contexts/appSettingContext'
import { DewellSetting } from '../../interfaces/dewell'
import useQuery from '../../hooks/useQuery'
import { mapQueriesValue } from '../../utils/utils.mapValue'

interface FormValues {
  intervals: DewellSetting[]
}

const DEFAULT_NUMBER = 15

const Setting = () => {
  const { popupState, displayPopup, hidePopup } = usePopup()
  const [modalType, setModalType] = useState<string | null>(null)
  const [isLoading, setLoading] = useState(false)
  const { t3StoreApiConfig } = useAppSettingContext()
  const dewellSettingInstance = useQuery({
    queryApi: async () => {
      const resGetDwellTimeSettingApi = await getDwellTimeSettingApi(t3StoreApiConfig)
      const data = resGetDwellTimeSettingApi?.result?.data

      if (resGetDwellTimeSettingApi?.result?.code !== 10001) {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
          message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
        })
        setModalType(MODAL_TYPE_NAME.ERROR)
      }

      return data ? mapQueriesValue<DewellSetting>(data, 'interval_id') : null
    },
  })

  const form = useForm<FormValues>({
    defaultValues: {
      intervals: dewellSettingInstance?.queryData || undefined,
    },
  })

  const { control, handleSubmit, setValue, getValues, register } = form

  const { fields, remove } = useFieldArray({
    control,
    name: 'intervals',
  })

  const style = useStyles2(getStylesFormInterval)

  useEffect(() => {
    if (dewellSettingInstance?.queryData) {
      form.reset({ intervals: dewellSettingInstance.queryData })
    }
  }, [dewellSettingInstance?.queryData])

  const onSubmit = (data: FormValues) => {
    if (data?.intervals?.length) {
      const isValid = validateIntervals(data.intervals)

      if (!isValid) {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.ERROR].title,
          message: getModalMessage(MODAL_TYPE_NAME.ERROR),
        })
        setModalType(MODAL_TYPE_NAME.ERROR)
      } else {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.CONFIRM].title,
          message: getModalMessage(MODAL_TYPE_NAME.CONFIRM),
        })
        setModalType(MODAL_TYPE_NAME.CONFIRM)
      }
    }
  }

  const handleClickAdd = () => {
    const currentIntervals = getValues('intervals')
    const lastInterval = currentIntervals[currentIntervals.length - 1]
    const minValue = lastInterval?.greater_than_or_equal_to ? Number(lastInterval.greater_than_or_equal_to) : null

    if (currentIntervals.length === 0) {
      const initValue = [
        { interval_id: 1, greater_than_or_equal_to: 0, less_than: DEFAULT_NUMBER },
        { interval_id: 2, greater_than_or_equal_to: DEFAULT_NUMBER, less_than: 0 },
      ]
      setValue('intervals', initValue)
    } else {
      if (minValue && typeof minValue === 'number') {
        const newMinValue = minValue + DEFAULT_NUMBER
        const lastIndex = Number(currentIntervals[currentIntervals.length - 1].interval_id)

        const newIntervals = [
          ...currentIntervals.slice(0, -1),
          { interval_id: lastIndex, greater_than_or_equal_to: minValue, less_than: newMinValue },
          { interval_id: lastIndex + 1, greater_than_or_equal_to: newMinValue, less_than: 0 },
        ]

        setValue('intervals', newIntervals)
      }
    }
  }

  const resetForm = () => {
    form.reset({ intervals: dewellSettingInstance?.queryData || [] })
  }

  const handleClickConfirmButton = () => {
    updateDwellTimeSetting()
    hidePopup()
  }

  const updateDwellTimeSetting = async () => {
    try {
      setLoading(true)
      const data = getValues('intervals')
      const newData = data?.map((interval, index) => ({ ...interval, interval_id: index + 1 }))
      const resUpdateDwellTimeSettingApi = await updateDwellTimeSettingApi(newData, t3StoreApiConfig)

      if (resUpdateDwellTimeSettingApi?.result?.code !== 10001) {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
          message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
        })
        setModalType(MODAL_TYPE_NAME.API_ERROR)
      } else {
        // reload get dewell setting
        dewellSettingInstance.reload()
      }
    } catch (error) {
      console.log('Error updateDwellTimeSetting :', error)
    } finally {
      setLoading(false)
    }
  }

  const displayModal = () => {
    switch (modalType) {
      case MODAL_TYPE_NAME.CONFIRM:
        return (
          <Popup {...popupState} okText="OK" onOk={handleClickConfirmButton} cancelText="Cancel" onCancel={hidePopup} />
        )
      case MODAL_TYPE_NAME.ERROR:
      case MODAL_TYPE_NAME.API_ERROR:
        return <Popup {...popupState} okText="OK" onOk={hidePopup} />
      default:
        return null
    }
  }

  return (
    <PluginPage>
      <SettingContainer>
        {dewellSettingInstance?.isLoading && <LoadingBar width={128} />}
        {dewellSettingInstance?.queryData && <SettingInfo data={dewellSettingInstance?.queryData} />}
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Space spacing={{ mb: 2, mt: 4 }}>
              <Text element="h4">Dwell Time Setting</Text>
            </Space>
            <Box gap={1}>
              {fields?.map((field, index) => {
                return (
                  <div key={field.id}>
                    <Field>
                      <div className={style.boxContainer}>
                        {/* interval id */}
                        <Text>{`Interval ${index + 1}: `}</Text>

                        {/* Greater than or equal to */}
                        {index > 0 && (
                          <>
                            <Text>Greater than or equal to</Text>
                            <InlineFieldRow>
                              <InputControl
                                control={control}
                                name={`intervals.${index}.greater_than_or_equal_to`}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    value={Number(field.value) || undefined}
                                    type="number"
                                    step="any"
                                    {...register(`intervals.${index}.greater_than_or_equal_to`, {
                                      valueAsNumber: true,
                                    })}
                                  />
                                )}
                              />
                            </InlineFieldRow>
                            <Text>{index > 0 && index !== fields.length - 1 ? 'min,' : 'min'}</Text>
                          </>
                        )}

                        {/* less than */}
                        {index !== fields.length - 1 && (
                          <>
                            <Text>less than </Text>
                            <InlineFieldRow>
                              <InputControl
                                control={control}
                                name={`intervals.${index}.less_than`}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    value={Number(field.value) || undefined}
                                    type="number"
                                    step="any"
                                    {...register(`intervals.${index}.less_than`, { valueAsNumber: true })}
                                  />
                                )}
                              />
                            </InlineFieldRow>
                            <Text>min</Text>
                          </>
                        )}

                        {/* delete interval */}
                        {index > 0 && index !== fields.length - 1 && (
                          <ButtonSetting
                            type="button"
                            fill="text"
                            onClick={() => remove(index)}
                            customStyles={style.deleteButton}
                          >
                            Delete Interval
                          </ButtonSetting>
                        )}
                      </div>
                    </Field>
                  </div>
                )
              })}
              {fields.length < 10 && (
                <Button type="button" fill="text" onClick={handleClickAdd}>
                  + Add Interval
                </Button>
              )}
            </Box>

            <Box gap={1} display="flex" justifyContent="flex-end">
              <Button variant="secondary" type="button" onClick={() => resetForm()}>
                Reset
              </Button>
              <Button type="submit" disabled={isLoading || dewellSettingInstance?.queryData === null}>
                Confirm
                {isLoading && <LoadingPlaceholder text="" style={{ marginLeft: '16px', marginTop: '30px' }} />}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </SettingContainer>

      {displayModal()}
    </PluginPage>
  )
}

export default Setting
