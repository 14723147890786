import { fetchGet, fetchPost } from '../utils/utils.apiControl'
import { T3StoreApiConfig } from '../interfaces/apiConfig'
import { DewellSetting } from '../interfaces/dewell'
import { v4 as uuidv4 } from 'uuid'

const createApiConfig = (t3StoreApiConfig: T3StoreApiConfig) => {
  return {
    headers: {
      Authorization: t3StoreApiConfig.apiKey,
    },
  }
}

const getDwellTimeSettingApi = async (t3StoreApiConfig: T3StoreApiConfig) => {
  let result = null,
    error = null

  try {
    result = await fetchGet(
      `${t3StoreApiConfig.apiUrl}/queries`,
      {
        q: 'iot.func_get_dwell_time',
      },
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: any) {
    console.log('Error getDwellTimeSettingApi : ', err)
    error = err
  }

  return { result, error }
}

const updateDwellTimeSettingApi = async (dewellSettings: DewellSetting[], t3StoreApiConfig: T3StoreApiConfig) => {
  let result = null,
    error = null

  try {
    result = await fetchPost(
      `${t3StoreApiConfig.apiUrl}/injection`,
      {
        event_timestamp: new Date().getTime(),
        event_name: 'm_dwell_time',
        event_id: uuidv4(),
        country_iso2: 'th',
        event_parameter: dewellSettings,
        x_correlation_id: uuidv4(),
      },
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: any) {
    console.log('Error updateDwellTimeSettingApi : ', err)
    error = err
  }

  return { result, error }
}

export { getDwellTimeSettingApi, updateDwellTimeSettingApi }
