import React, { useState, useEffect } from 'react'
import {
  useStyles2,
  Text,
  InlineField,
  InlineFieldRow,
  Input,
  InputControl,
  Button,
  LoadingBar,
  LoadingPlaceholder,
} from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'
import { useForm, FormProvider, useFieldArray } from 'react-hook-form'
import DeduplicationPeroidInfo from './deduplicationPeriodInfo'
import { isValidTime } from '../../../utils/utils.validateNumber'
import Popup, { usePopup } from '../../../components/popup'
import { MODAL_TYPE, MODAL_TYPE_NAME, getModalMessage } from '../../../utils/utils.modalType'
import { getQueries } from '../../../apis/queriesApi'
import { updatePeopleCountingDeduplicationSetting } from '../../../apis/peoplecountingApi'
import { useAppSettingContext } from '../../../contexts/appSettingContext'
import useQuery from '../../../hooks/useQuery'
import { mapQueriesValue } from '../../../utils/utils.mapValue'
import { PeopleCountingDeduplicationSetting } from '../../../interfaces/peopleCounting'

interface FormValues {
  deduplicationPeriod: PeopleCountingDeduplicationSetting[]
}

const DeduplicationPeriod: React.FC = () => {
  const { popupState, displayPopup, hidePopup } = usePopup()
  const [modalType, setModalType] = useState<string | null>(null)
  const style = useStyles2(getStyles)
  const { t3StoreApiConfig } = useAppSettingContext()
  const [isLoading, setLoading] = useState(false)

  const deduplicationPeriodData = useQuery({
    queryApi: async () => {
      const resSetting: any = await getQueries({ q: 'iot.func_get_people_counting_deduplication' }, t3StoreApiConfig)
      const data = resSetting?.result?.data

      if (resSetting?.result?.code !== 10001) {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
          message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
        })
        setModalType(MODAL_TYPE_NAME.ERROR)
      }

      return data ? mapQueriesValue<PeopleCountingDeduplicationSetting>(data, 'id') : null
    },
  })

  const form = useForm<FormValues>({
    defaultValues: {
      deduplicationPeriod: deduplicationPeriodData?.queryData || undefined,
    },
  })

  const { control, handleSubmit, getValues, register } = form

  const { fields } = useFieldArray({
    control,
    name: 'deduplicationPeriod',
  })

  const onSubmit = (data: FormValues) => {
    const { deduplicationPeriod } = data
    const isValid = deduplicationPeriod?.every(({ period_hour, period_minute }) => {
      return isValidTime(period_hour, period_minute)
    })

    if (!isValid) {
      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
        message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
      })
      setModalType(MODAL_TYPE_NAME.API_ERROR)
    } else {
      displayPopup({
        title: MODAL_TYPE[MODAL_TYPE_NAME.CONFIRM].title,
        message: getModalMessage(MODAL_TYPE_NAME.CONFIRM),
      })
      setModalType(MODAL_TYPE_NAME.CONFIRM)
    }
  }

  const handleClickConfirmButton = () => {
    updateSetting()
    hidePopup()
  }

  const updateSetting = async () => {
    try {
      setLoading(true)
      const data = getValues('deduplicationPeriod')
      const newData: PeopleCountingDeduplicationSetting[] = data?.map((item, index) => ({ ...item, id: index + 1 }))

      const resUpdateSettingApi = await updatePeopleCountingDeduplicationSetting(newData, t3StoreApiConfig)

      if (resUpdateSettingApi?.result?.code !== 10001) {
        displayPopup({
          title: MODAL_TYPE[MODAL_TYPE_NAME.API_ERROR].title,
          message: getModalMessage(MODAL_TYPE_NAME.API_ERROR),
        })
        setModalType(MODAL_TYPE_NAME.API_ERROR)
      } else {
        deduplicationPeriodData.reload()
      }
    } catch (error) {
      console.log('Error updateSetting :', error)
    } finally {
      setLoading(false)
    }
  }

  const resetForm = () => {
    form.reset({ deduplicationPeriod: deduplicationPeriodData?.queryData || [] })
  }

  const displayModal = () => {
    switch (modalType) {
      case MODAL_TYPE_NAME.CONFIRM:
        return (
          <Popup {...popupState} okText="OK" onOk={handleClickConfirmButton} cancelText="Cancel" onCancel={hidePopup} />
        )
      case MODAL_TYPE_NAME.API_ERROR:
        return <Popup {...popupState} okText="OK" onOk={hidePopup} />
      default:
        return null
    }
  }

  useEffect(() => {
    if (deduplicationPeriodData?.queryData) {
      form.reset({ deduplicationPeriod: deduplicationPeriodData.queryData })
    }
  }, [deduplicationPeriodData?.queryData])

  return (
    <div className={style.container}>
      {deduplicationPeriodData?.isLoading && <LoadingBar width={128} />}
      <DeduplicationPeroidInfo />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InlineFieldRow className={style.formContainer}>
            {fields?.map((field, index) => {
              return (
                <>
                  <Text>Deduplication Period: </Text>
                  <InlineField>
                    <InputControl
                      control={control}
                      name={`deduplicationPeriod.${index}.period_hour`}
                      render={({ field }) => (
                        <Input
                          {...field}
                          data-testid={'hour'}
                          value={Number(field.value) || undefined}
                          type="number"
                          min={0}
                          max={23}
                          {...register(`deduplicationPeriod.${index}.period_hour`, {
                            valueAsNumber: true,
                          })}
                          onKeyDown={(e) => {
                            if (['e', 'E', '+', '-', '.', ','].includes(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                      )}
                    />
                  </InlineField>
                  <Text>Hour</Text>
                  <InlineField>
                    <InputControl
                      control={control}
                      name={`deduplicationPeriod.${index}.period_minute`}
                      render={({ field }) => (
                        <Input
                          {...field}
                          data-testid={'minute'}
                          value={Number(field.value) || undefined}
                          type="number"
                          min={0}
                          max={59}
                          {...register(`deduplicationPeriod.${index}.period_minute`, {
                            valueAsNumber: true,
                          })}
                          onKeyDown={(e) => {
                            if (['e', 'E', '+', '-', '.', ','].includes(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                      )}
                    />
                  </InlineField>
                  <Text>Minute</Text>
                </>
              )
            })}
          </InlineFieldRow>
          <div className={style.box}>
            <Button variant="secondary" type="button" onClick={() => resetForm()}>
              Reset
            </Button>
            <Button type="submit" disabled={isLoading || deduplicationPeriodData?.queryData === null}>
              Confirm
              {isLoading && <LoadingPlaceholder text="" style={{ marginLeft: '16px', marginTop: '30px' }} />}
            </Button>
          </div>
        </form>
      </FormProvider>
      {displayModal()}
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    box: css`
      display: flex;
      gap: ${theme.spacing(1)};
      margin-top: ${theme.spacing(1)};

      ${theme.breakpoints.down('sm')} {
        margin-top: ${theme.spacing(2)};
        gap: ${theme.spacing(2)};
      }
    `,
    formContainer: css`
      display: flex;
      justify-items: center;
      align-items: center;
      gap: ${theme.spacing(1)};
      input {
        min-width: 100px;
      }
      ${theme.breakpoints.down('sm')} {
        display: grid;
        justify-items: center;
        align-items: center;
        text-align: center;
        grid-template-rows: auto;
        grid-template-columns: repeat(4, 1fr);

        > :first-child {
          grid-column: 1 / -1;
          margin-bottom: ${theme.spacing(1)};
          text-align: left;
          text-align: left;
          width: 100%;
        }

        input {
          min-width: 80px;
        }
      }
    `,
  }
}

export default DeduplicationPeriod
