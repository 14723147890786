import { fetchGetFile, fetchPost } from '../utils/utils.apiControl'
import { T3StoreApiConfig } from '../interfaces/apiConfig'
import { v4 as uuidv4 } from 'uuid'
import { PeopleCountingSetting, PeopleCountingDeduplicationSetting } from '../interfaces/peopleCounting'

export const createApiConfig = (t3StoreApiConfig: T3StoreApiConfig) => {
  return {
    headers: {
      Authorization: t3StoreApiConfig.apiKey,
    },
  }
}

const exportPeopleCountingReportApi = async (
  {
    startDate,
    endDate,
    startTime,
    endTime,
    storeNames = [],
  }: {
    startDate: string
    endDate: string
    startTime?: string
    endTime?: string
    storeNames?: string[]
  },
  t3StoreApiConfig: T3StoreApiConfig
) => {
  let result = null,
    error = null

  try {
    result = await fetchGetFile(
      `${t3StoreApiConfig.apiUrl}/t3store/people-counting/report`,
      {
        startDate,
        endDate,
        startTime,
        endTime,
        storeNames: storeNames?.join(''),
      },
      {
        responseType: 'blob',
        ...createApiConfig(t3StoreApiConfig),
      }
    )
  } catch (err: any) {
    console.log('Error exportPeopleCountingReportApi : ', err)
    error = err
  }

  return { result, error }
}

const updatePeopleCountingSetting = async (
  peopleCountingSetting: PeopleCountingSetting[],
  t3StoreApiConfig: T3StoreApiConfig
) => {
  let result = null,
    error = null

  try {
    result = await fetchPost(
      `${t3StoreApiConfig.apiUrl}/injection`,
      {
        event_timestamp: new Date().getTime(),
        event_name: 'm_people_counting',
        event_id: uuidv4(),
        country_iso2: 'th',
        event_parameter: peopleCountingSetting,
        x_correlation_id: uuidv4(),
      },
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: any) {
    console.log('Error updatePeopleCountingSetting : ', err)
    error = err
  }

  return { result, error }
}

const updatePeopleCountingDeduplicationSetting = async (
  setting: PeopleCountingDeduplicationSetting[],
  t3StoreApiConfig: T3StoreApiConfig
) => {
  let result = null,
    error = null

  try {
    result = await fetchPost(
      `${t3StoreApiConfig.apiUrl}/injection`,
      {
        event_timestamp: new Date().getTime(),
        event_name: 'm_people_counting_deduplication',
        event_id: uuidv4(),
        country_iso2: 'th',
        event_parameter: setting,
        x_correlation_id: uuidv4(),
      },
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: any) {
    console.log('Error updatePeopleCountingDeduplicationSetting : ', err)
    error = err
  }

  return { result, error }
}

export { exportPeopleCountingReportApi, updatePeopleCountingSetting, updatePeopleCountingDeduplicationSetting }
