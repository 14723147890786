import pluginJson from './plugin.json'

export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`

export enum ROUTES {
  Home = 'home',
  DwellTimeSetting = 'dwelltime/setting',
  PeopleCountingReport = 'peoplecounting/report',
  PeopleCountingSetting = 'peoplecounting/setting',
  UserSetting = 'user/setting',
  HeatmapStoreSetting = 'heatmap/storesetting',
}
