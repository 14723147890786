import React, { createContext, useContext } from 'react'
import { Profile } from '../interfaces/profile'
import useUserProfile from '../hooks/useUserProfile'

interface UserProfileContextType {
  userProfile: Profile | null
}

const UserProfileContext = createContext<UserProfileContextType>({
  userProfile: {},
})

export const UserProfileProvider: React.FC<any> = ({ children }) => {
  const { userProfile } = useUserProfile()

  const contextData = {
    userProfile,
  }

  return <UserProfileContext.Provider value={contextData}>{children}</UserProfileContext.Provider>
}

export const useUserProfileContext = () => {
  return useContext(UserProfileContext)
}
