import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'

export const getStylesFormInterval = (theme: GrafanaTheme2) => ({
  boxContainer: css`
    ${theme.breakpoints.up('sm')} {
      display: flex;
      justify-items: center;
      align-items: center;
      gap: ${theme.spacing(1)};
    }
    ${theme.breakpoints.down('sm')} {
      display: grid;
      align-items: center;
      gap: ${theme.spacing(0.5)};
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: ${theme.spacing(0.5)};
    }
  `,
  deleteButton: css`
    ${theme.breakpoints.down('sm')} {
      button {
        padding: 0 !important;
        height: 22px !important;
      }
    }

    ${theme.breakpoints.up('sm')} {
      margin-left: auto;
    }
  `,
})
