import React, { useMemo } from 'react'

import { useStyles2, RadioButtonGroup, Text } from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'

interface Option {
  label: string
  value: string
  icon: any
}

interface FileTypeFieldProps {
  options: Option[]
}

const FileTypeOption: React.FC<Option> = ({ label, icon }) => {
  const style = useStyles2(getStyles)

  return (
    <div className={style.container}>
      {icon}
      <Text>{label}</Text>
    </div>
  )
}

const FileTypeField: React.FC<FileTypeFieldProps> = ({ options = [], ...field }) => {
  const radioOptions = useMemo(() => {
    return options?.map((option) => ({
      label: (<FileTypeOption {...option} />) as any,
      value: option?.value,
    }))
  }, [options])

  return <RadioButtonGroup {...field} options={radioOptions} size="md" />
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    justify-content: flex-start;
    column-gap: ${theme.spacing(1)};
    align-items: center;
  `,
})

export default FileTypeField
