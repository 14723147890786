import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppRootProps } from '@grafana/data'
import { AppSettingProvider } from '../../contexts/appSettingContext'
import { UserProfileProvider } from '../../contexts/userProfileContext'
import { ROUTES } from '../../constants'
import {
  PeopleCountingReport,
  NotFoundPage,
  DWellTimeSetting,
  Landing,
  PeopleCountingSetting,
  UserSetting,
  HeatmapStoreSetting,
} from '../../pages'

export function App(props: AppRootProps) {
  return (
    <AppSettingProvider appMeta={props?.meta}>
      <UserProfileProvider>
        <Routes>
          <Route path={ROUTES.Home} element={<Landing />} />
          <Route path={ROUTES.PeopleCountingReport} element={<PeopleCountingReport />} />
          <Route path={ROUTES.DwellTimeSetting} element={<DWellTimeSetting />} />
          <Route path={ROUTES.PeopleCountingSetting} element={<PeopleCountingSetting />} />
          <Route path={ROUTES.UserSetting} element={<UserSetting />} />
          <Route path={ROUTES.HeatmapStoreSetting} element={<HeatmapStoreSetting />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </UserProfileProvider>
    </AppSettingProvider>
  )
}
