import { useState, useEffect } from 'react'
import { Profile } from '../interfaces/profile'
import { getUserProfileApi } from '../apis/userApi'

const useUserProfile = () => {
  const [userProfile, setUserProfile] = useState<Profile | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resGetUserProfile = await getUserProfileApi()

        if (resGetUserProfile?.result?.id) {
          setUserProfile({ isLogin: true, ...resGetUserProfile.result })
        } else {
          setUserProfile({ isLogin: false })
        }
      } catch (error) {
        console.error('Error fetching user profile:', error)
      }
    }
    fetchData()
  }, [])

  return { userProfile }
}

export default useUserProfile
