export const isPositiveInteger = (value: number | undefined | null): boolean => {
  if (!value) {
    return false
  }

  const number = Number(value)
  return !isNaN(number) && number > 0 && Number.isInteger(number)
}

export const isValidTime = (period_hour: number | undefined, period_minute: number | undefined): boolean => {
  if (period_hour === undefined || period_minute === undefined) {
    return false
  }

  return (
    Number.isInteger(period_hour) &&
    period_hour >= 0 &&
    period_hour < 24 &&
    Number.isInteger(period_minute) &&
    period_minute >= 0 &&
    period_minute < 60
  )
}
