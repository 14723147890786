import { DateTime, dateTime } from '@grafana/data'

const convertDateToFormat = (date?: string | Date, format = 'YYYY-MM-DD') => {
  let result = ''

  if (date) {
    const convertResult = dateTime(date).format(format)
    if (convertResult !== 'Invalid date') {
      result = convertResult
    }
  }

  return result
}

const convertDateTimeToHoursMin = (date?: DateTime) => {
  let result = ''

  if (date) {
    const convertResult = dateTime(date).toDate().toLocaleTimeString('th-TH', { hour: '2-digit', minute: '2-digit' })

    if (convertResult !== 'Invalid Date') {
      result = convertResult
    }
  }

  return result
}

export { convertDateToFormat, convertDateTimeToHoursMin }
