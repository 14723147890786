import { BackendSrvRequest, getBackendSrv } from '@grafana/runtime'
import { firstValueFrom } from 'rxjs'
import { removeEmptyInObject } from './utils.object'

const fetchGet = <TResponse = any>(url: string, params: any, option?: Partial<BackendSrvRequest>) => {
  return getBackendSrv().get<TResponse>(url, removeEmptyInObject(params), undefined, option)
}

const fetchGetFile = <TResponse = any>(url: string, params: any, option?: Partial<BackendSrvRequest>) => {
  return firstValueFrom(
    getBackendSrv().fetch<TResponse>({
      url,
      params: removeEmptyInObject(params),
      method: 'GET',
      ...option,
    })
  )
}

const fetchPost = <TResponse = any>(url: string, params: any, option?: Partial<BackendSrvRequest>) => {
  return getBackendSrv().post<TResponse>(url, removeEmptyInObject(params), option)
}

const fetchPatch = <TResponse = any>(url: string, params: any, option?: Partial<BackendSrvRequest>) => {
  return getBackendSrv().patch<TResponse>(url, removeEmptyInObject(params), option)
}

export { fetchGet, fetchGetFile, fetchPost, fetchPatch }
