import { T3StoreApiConfig } from '../interfaces/apiConfig'
import { AddStoreImages, ErrorAddStoreImages, StoreHeatmap, StoreHeatmapPicture } from '../interfaces/heatmap'
import { createApiConfig } from './peoplecountingApi'
import { v4 as uuidv4 } from 'uuid'
import { fetchPost } from '../utils/utils.apiControl'

const addStoreImages = async (storeId: number, formData: FormData, t3StoreApiConfig: T3StoreApiConfig) => {
  let result: AddStoreImages | null = null,
    error: ErrorAddStoreImages | null = null

  try {
    const response = await fetch(`${t3StoreApiConfig.apiUrl}/stores/${storeId}/image`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: t3StoreApiConfig.apiKey,
      },
    })

    if (response?.ok) {
      result = await response.json()
    } else if (response.status === 400) {
      const errorData = await response.json()
      error = { message: errorData?.error?.message || '' }
    }
  } catch (err: any) {
    console.log('Error addStoreImages : ', err)
    error = err
  }

  return { result, error }
}

const addStoreHeatmapApi = async (storeHeatmapParams: StoreHeatmap[], t3StoreApiConfig: T3StoreApiConfig) => {
  let result = null,
    error = null

  try {
    result = await fetchPost(
      `${t3StoreApiConfig.apiUrl}/injection`,
      {
        event_timestamp: new Date().getTime(),
        event_name: 'm_store_heatmap',
        event_id: uuidv4(),
        country_iso2: 'th',
        event_parameter: storeHeatmapParams,
        x_correlation_id: uuidv4(),
      },
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: any) {
    console.log('Error addStoreHeatmapApi : ', err)
    error = err
  }

  return { result, error }
}

const addStoreHeatmapPictureApi = async (
  storeHeatmapParams: StoreHeatmapPicture[],
  t3StoreApiConfig: T3StoreApiConfig
) => {
  let result = null,
    error = null

  try {
    result = await fetchPost(
      `${t3StoreApiConfig.apiUrl}/injection`,
      {
        event_timestamp: new Date().getTime(),
        event_name: 'm_store_heatmap_picture',
        event_id: uuidv4(),
        country_iso2: 'th',
        event_parameter: storeHeatmapParams,
        x_correlation_id: uuidv4(),
      },
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: any) {
    console.log('Error addStoreHeatmapPictureApi : ', err)
    error = err
  }

  return { result, error }
}

export { addStoreImages, addStoreHeatmapApi, addStoreHeatmapPictureApi }
