import React, { ReactNode, ComponentProps } from 'react'
import { Button, useStyles2 } from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'

interface ButtonComponentProps extends ComponentProps<typeof Button> {
  children: ReactNode
  customStyles?: string
}

const ButtonSetting: React.FC<ButtonComponentProps> = ({ children, customStyles, ...props }) => {
  const style = useStyles2(getStyles)

  return (
    <div className={css(style, customStyles)}>
      <Button {...props}>{children}</Button>
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css``,
})

export default ButtonSetting
