import React from 'react'
import { useStyles2 } from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'
import { PluginPage } from '@grafana/runtime'

import Tabs from '../../components/tabs'
import PeopleCountingExport from '../../components/peopleCountingExport'
import PeopleCountingReportByEmail from '../../components/peopleCountingReportByEmail'
import PeopleCountingAllExport from '../../components/peopleCountingAllExport'

const Report = () => {
  const style = useStyles2(getStyles)

  return (
    <PluginPage>
      <div className={style.container}>
        <Tabs
          tabs={[
            { label: 'Export', component: <PeopleCountingExport /> },
            { label: 'Report By Email', component: <PeopleCountingReportByEmail /> },
            { label: 'All Data Export', component: <PeopleCountingAllExport /> },
          ]}
        />
      </div>
    </PluginPage>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: column;
  `,
})

export default Report
