import { useState, useEffect, useCallback } from 'react'

interface UseQueryProps<T> {
  queryApi: () => Promise<T>
}

const useQuery = <T = any>({ queryApi }: UseQueryProps<T>) => {
  const [queryData, setQueryData] = useState<T | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getQueryData = useCallback(async () => {
    try {
      setIsLoading(true)
      const data = await queryApi()
      setQueryData(data || null)
    } catch (error) {
      console.log('Error getQueryData :', error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getQueryData()
  }, [getQueryData])

  return {
    queryData,
    isLoading,
    reload: getQueryData,
  }
}

export default useQuery
