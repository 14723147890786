import { isPositiveInteger } from './utils.validateNumber'
import { PeopleCountingSetting } from '../interfaces/peopleCounting'

export const validateAgeGroup = (ageGroup: PeopleCountingSetting[]): boolean => {
  if (ageGroup.length === 0) {
    return false
  }

  for (let i = 0; i < ageGroup.length; i++) {
    const { greater_than_or_equal_to, less_than } = ageGroup[i]
    const minVal = Number(greater_than_or_equal_to)
    const maxVal = Number(less_than)

    if (!isPositiveInteger(minVal) || !isPositiveInteger(maxVal) || minVal >= maxVal) {
      return false
    }

    if (i < ageGroup.length - 1) {
      const nextMinVal = Number(ageGroup[i + 1].greater_than_or_equal_to) || 0
      if (isPositiveInteger(nextMinVal) && maxVal >= nextMinVal) {
        return false
      }
    }
  }

  return true
}
