import React, { Fragment, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, DatePickerWithInput, InputControl, Select, useStyles2 } from '@grafana/ui'
import { SelectableValue, GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'

import { FileEarmarkExcel } from '@styled-icons/bootstrap/FileEarmarkExcel'
import { FiletypeCsv } from '@styled-icons/bootstrap/FiletypeCsv'

import { HorizontalField, FileTypeField } from '../../components/formField'
import { TIME_OPTIONS } from '../../constants/selectOptionsConstants'

const FILE_TYPE_OPTIONS = [
  { label: 'CSV', value: 'csv', icon: <FileEarmarkExcel size={18} /> },
  { label: 'Excel', value: 'excel', icon: <FiletypeCsv size={18} /> },
]

const STORE_OPTIONS = [{ label: 'all', value: 'all' }]

const DetailForm = () => {
  const style = useStyles2(getStyles)
  const { control, setValue, getValues } = useFormContext()

  const onChangeStartDate = (startDate: string | Date) => {
    const endDate = getValues('endDate')
    if (endDate) {
      const startDateZeroTime = new Date(startDate)
      startDateZeroTime.setHours(0, 0, 0, 0)
      const endDateZeroTime = new Date(endDate)
      endDateZeroTime.setHours(0, 0, 0, 0)

      if (startDateZeroTime > endDateZeroTime) {
        setValue('endDate', startDate)
      }
    }
  }

  const onChangeEndDate = (endDate: string | Date) => {
    const startDate = getValues('startDate')
    if (startDate) {
      const startDateZeroTime = new Date(startDate)
      startDateZeroTime.setHours(0, 0, 0, 0)
      const endDateZeroTime = new Date(endDate)
      endDateZeroTime.setHours(0, 0, 0, 0)

      if (endDateZeroTime < startDateZeroTime) {
        setValue('startDate', endDate)
      }
    }
  }

  const onChangeStartTime = (e: SelectableValue<string>) => {
    const startTime = e?.value || ''
    const endTime = getValues('endTime')?.value
    if (endTime) {
      const startTimeInt = parseInt(startTime.replace(':', ''), 10)
      const endTimeInt = parseInt(endTime.replace(':', ''), 10)
      if (startTimeInt > endTimeInt) {
        setValue('endTime', e)
      }
    }
  }

  const onChangeEndTime = (e: SelectableValue<string>) => {
    const startTime = getValues('startTime')?.value
    const endTime = e?.value || ''
    if (startTime) {
      const startTimeInt = parseInt(startTime.replace(':', ''), 10)
      const endTimeInt = parseInt(endTime.replace(':', ''), 10)
      if (endTimeInt < startTimeInt) {
        setValue('startTime', e)
      }
    }
  }

  const minDate = useMemo(() => {
    const currentDate = new Date()
    // - 3 month
    currentDate.setMonth(currentDate.getMonth() - 3)
    return currentDate
  }, [])

  const maxDate = useMemo(() => {
    const currentDate = new Date()
    return currentDate
  }, [])

  return (
    <Fragment>
      <HorizontalField label="File Type" required>
        <InputControl
          render={({ field }) => <FileTypeField {...field} options={FILE_TYPE_OPTIONS} />}
          control={control}
          name="fileType"
        />
      </HorizontalField>

      <HorizontalField label="Store">
        <InputControl
          render={({ field }) => <Select {...field} options={STORE_OPTIONS} className={style.storeSelect} />}
          control={control}
          name="store"
          disabled
        />
      </HorizontalField>

      <HorizontalField label="Date" required>
        <Box gap={1} display="flex" alignItems="center">
          <InputControl
            render={({ field }) => (
              <DatePickerWithInput
                {...field}
                closeOnSelect
                placeholder="Start date"
                minDate={minDate}
                maxDate={maxDate}
                className={style.dateInput}
                onChange={(e) => {
                  field.onChange(e)
                  onChangeStartDate(e)
                }}
              />
            )}
            control={control}
            name="startDate"
          />
          <label>-</label>
          <InputControl
            render={({ field }) => (
              <DatePickerWithInput
                {...field}
                closeOnSelect
                placeholder="End date"
                minDate={minDate}
                maxDate={maxDate}
                className={style.dateInput}
                onChange={(e) => {
                  field.onChange(e)
                  onChangeEndDate(e)
                }}
              />
            )}
            control={control}
            name="endDate"
          />
        </Box>
      </HorizontalField>

      <HorizontalField label="Time" required>
        <Box gap={1} display="flex" alignItems="center">
          <InputControl
            render={({ field }) => (
              <Select<string>
                {...field}
                options={TIME_OPTIONS}
                className={style.timeInput}
                onChange={(e) => {
                  field.onChange(e)
                  onChangeStartTime(e)
                }}
              />
            )}
            control={control}
            name="startTime"
          />
          <label>-</label>
          <InputControl
            render={({ field }) => (
              <Select<string>
                {...field}
                options={TIME_OPTIONS}
                className={style.timeInput}
                onChange={(e) => {
                  field.onChange(e)
                  onChangeEndTime(e)
                }}
              />
            )}
            control={control}
            name="endTime"
          />
        </Box>
      </HorizontalField>
    </Fragment>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css``,
  storeSelect: css`
    input {
      min-width: 182px !important;
    }
  `,
  dateInput: css`
    input {
      max-width: 100px !important;
      min-width: 68px !important;
    }
  `,
  timeInput: css`
    input {
      min-width: 58px !important;
    }
  `,
})

export default DetailForm
