import React, { useEffect, useState } from 'react'

import { PluginPage } from '@grafana/runtime'

import { getQueries } from '../../apis/queriesApi'
import { useAppSettingContext } from '../../contexts/appSettingContext'
import { mapQueriesValue } from '../../utils/utils.mapValue'
import { SearchUserPage, AddUserPage } from '../../components/userSetting'
import { SelectOptions } from '../../interfaces/form'
import { FormattedStore, Store, EditUserState } from '../../interfaces/settingUser'
import { useUserProfileContext } from '../../contexts/userProfileContext'

export enum SettingPageEnum {
  ADD = 'add',
  SEARCH = 'search',
}

const UserSetting = () => {
  const { t3StoreApiConfig } = useAppSettingContext()
  const { userProfile } = useUserProfileContext()

  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true)
  const [settingPage, setSettingPage] = useState<SettingPageEnum>(SettingPageEnum.SEARCH)
  const [storesData, setStoresData] = useState<FormattedStore[]>([])
  const [rolesData, setRolesData] = useState<SelectOptions[]>([])
  const [editUserState, setEditUserState] = useState<EditUserState>({
    isEditUser: false,
    user: null,
  })

  const formatStores = (data: Store[]): FormattedStore[] => {
    const storeMap: { [key: number]: FormattedStore } = {}

    data.forEach((store) => {
      if (store.store_type === 'store') {
        storeMap[store.store_id] = {
          store_id: store.store_id,
          store_name: store.store_name,
          branchs: [],
        }
      }
    })

    data.forEach((store) => {
      if (store.store_type === 'branch' && store.store_parent_id !== null) {
        const parentStore = storeMap[store.store_parent_id]
        if (parentStore) {
          parentStore.branchs.push({
            store_id: store.store_id,
            store_name: store.store_name,
            branchs: [],
          })
        }
      }
    })

    return Object.values(storeMap)
  }

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      try {
        // Fetch store data
        const { result: storesResult } = await getQueries({ q: 'iot.func_get_stores' }, t3StoreApiConfig)
        const storesData = mapQueriesValue<Store>(storesResult?.data)
        const formattedStores = formatStores(storesData)

        // Fetch role data
        const { result: rolesResult } = await getQueries({ q: 'iot.func_get_roles' }, t3StoreApiConfig)
        const rolesData = mapQueriesValue<{ role_name: string; role_id: number }>(rolesResult?.data)
        const roleOptions: SelectOptions[] = [
          { label: 'All', value: '' },
          ...rolesData.map(({ role_name, role_id }) => ({
            label: role_name,
            value: role_id,
          })),
        ]

        if (isMounted) {
          setStoresData(formattedStores)
          setRolesData(roleOptions)
          setIsLoadingPage(false)
        }
      } catch (error) {
        console.error('Failed to fetch data', error)
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [t3StoreApiConfig])

  const renderPageContent = () => {
    switch (settingPage) {
      case SettingPageEnum.SEARCH:
        return (
          <SearchUserPage
            stores={storesData}
            roles={rolesData}
            setSettingPage={setSettingPage}
            setEditUserState={setEditUserState}
            userProfile={userProfile}
          />
        )
      case SettingPageEnum.ADD:
        return (
          <AddUserPage
            stores={storesData}
            roles={rolesData}
            setSettingPage={setSettingPage}
            userProfile={userProfile}
            editUserState={editUserState}
          />
        )
      default:
        return null
    }
  }

  return <PluginPage renderTitle={() => <h1>Users</h1>}>{!isLoadingPage && renderPageContent()}</PluginPage>
}

export default UserSetting
