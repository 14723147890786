import { AppPlugin } from '@grafana/data'
import { App } from './components/app'
import { AppConfig } from './components/appConfig'

export const plugin = new AppPlugin<{}>().setRootPage(App).addConfigPage({
  title: 'Configuration',
  icon: 'cog',
  body: AppConfig,
  id: 'configuration',
})
