import React, { useState, useMemo } from 'react'
import { RadioButtonGroup, InputControl, Button, useStyles2, LoadingPlaceholder } from '@grafana/ui'
import { GrafanaTheme2, SelectableValue } from '@grafana/data'
import { css } from '@emotion/css'
import { FormProvider, useForm } from 'react-hook-form'

import { exportPeopleCountingReportApi } from '../../apis/peoplecountingApi'
import { convertDateToFormat } from '../../utils/utils.dateTime'
import { getFileNameFromContentDispositionHeader } from '../../utils/utils.response'
import { downloadBlobFile } from '../../utils/utils.file'
import { TIME_OPTIONS } from '../../constants/selectOptionsConstants'
import { useAppSettingContext } from '../../contexts/appSettingContext'

import { HorizontalField } from '../../components/formField'
import Popup, { usePopup } from '../../components/popup'
import DetailForm from './detailForm'

const DATA_TYPE = {
  DETAIL: 'detail',
  STATISTICS: 'statistics',
  RANKING: 'ranking',
}

const DATA_TYPE_OPTIONS = [
  { label: 'Detail', value: DATA_TYPE.DETAIL },
  { label: 'Statistics', value: DATA_TYPE.STATISTICS },
  { label: 'Ranking', value: DATA_TYPE.RANKING },
]

interface FormData {
  dataType?: string
  store?: string
  startDate?: string | Date
  endDate?: string | Date
  startTime?: SelectableValue<string>
  endTime?: SelectableValue<string>
  fileType?: string
}

const ExportPeopleCounting = () => {
  const [isLoading, setLoading] = useState(false)
  const { popupState, displayPopup, hidePopup } = usePopup()
  const { t3StoreApiConfig } = useAppSettingContext()
  const style = useStyles2(getStyles)
  const form = useForm<FormData>({
    defaultValues: {
      dataType: 'detail',
      store: 'all',
      startTime: TIME_OPTIONS[9], // 09:00
      endTime: TIME_OPTIONS[22], // 22:00
      fileType: 'csv',
    },
  })
  const dataType = form.watch('dataType')
  const startDate = form.watch('startDate')
  const endDate = form.watch('endDate')

  const isEnableExportButton = useMemo(() => startDate && endDate, [startDate, endDate])

  const onSubmit = async (value: FormData) => {
    try {
      setLoading(true)
      const { result, error } = await exportPeopleCountingReportApi(
        {
          startDate: convertDateToFormat(value?.startDate, 'YYYY-MM-DD'),
          endDate: convertDateToFormat(value?.endDate, 'YYYY-MM-DD'),
          startTime: value?.startTime?.value,
          endTime: value?.endTime?.value,
        },
        t3StoreApiConfig
      )

      // download file
      if (result?.data) {
        const fileName = getFileNameFromContentDispositionHeader(result?.headers)
        downloadBlobFile(result?.data, fileName)
      } else if (error?.status === 404) {
        displayPopup({ title: 'Oooops!', message: 'No data found!' })
      } else {
        displayPopup({ title: 'Oooops!', message: 'Sorry, Something went wrong.' })
      }
    } catch (err) {
      console.log('Error export file :', err)
      displayPopup({ title: 'Oooops!', message: 'Sorry, Something went wrong.' })
    } finally {
      setLoading(false)
    }
  }

  const renderFormByDataType = () => {
    switch (dataType) {
      case DATA_TYPE.DETAIL:
        return <DetailForm />
      case DATA_TYPE.STATISTICS:
        return <div>statistics</div>
      case DATA_TYPE.RANKING:
        return <div>ranking</div>
      default:
        return <React.Fragment />
    }
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className={style.container}>
        <HorizontalField label="Data Type">
          <InputControl
            render={({ field }) => <RadioButtonGroup {...field} options={DATA_TYPE_OPTIONS} />}
            control={form.control}
            name="dataType"
          />
        </HorizontalField>
        {renderFormByDataType()}
        <div className={style.buttonContainer}>
          <Button fill="outline" variant="secondary">
            View Samples
          </Button>
          <Button fill="outline" variant="secondary" icon="download-alt" type="submit" disabled={!isEnableExportButton}>
            Export
            {isLoading && <LoadingPlaceholder text="" className={style.loading} />}
          </Button>
        </div>
      </form>

      <Popup {...popupState} okText="OK" onOk={hidePopup} />
    </FormProvider>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    position: relative;
  `,
  buttonContainer: css`
    display: flex;
    flex-direction: row;
    column-gap: ${theme.spacing(1)};
    position: absolute;
    right: 0px;
    top: 0;
    ${theme.breakpoints.down('md')} {
      position: relative;
      justify-content: end;
    }
  `,
  loading: css`
    margin-left: ${theme.spacing(1)};
    margin-bottom: 0 !important;
  `,
})

export default ExportPeopleCounting
