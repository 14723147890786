import { getBackendSrv } from '@grafana/runtime'
import { Profile } from '../interfaces/profile'
import { T3StoreApiConfig } from '../interfaces/apiConfig'
import { fetchPost, fetchPatch } from '../utils/utils.apiControl'
import { createApiConfig } from './peoplecountingApi'
import { AddUser, UpdateUser, UserById } from '../interfaces/settingUser'
import { getQueries } from '../apis/queriesApi'
import { mapQueriesValue } from '../utils/utils.mapValue'

const getUserProfileApi = async () => {
  let result: Profile | null = null
  let error = null

  try {
    result = await getBackendSrv().get('/api/user')
  } catch (err) {
    console.log('Error getUserProfileApi : ', err)
    error = err
  }

  return { result, error }
}

const addUserApi = async (params: AddUser, t3StoreApiConfig: T3StoreApiConfig) => {
  let result = null,
    error = null

  try {
    result = await fetchPost(`${t3StoreApiConfig.apiUrl}/users`, params, createApiConfig(t3StoreApiConfig))
  } catch (err: any) {
    console.log('Error addUserApi : ', err)
    error = err
  }

  return { result, error }
}

const resetUserPasswordApi = async (grafanaUserId: number | null | undefined, newPassword: string) => {
  let result
  let error = null

  try {
    result = await getBackendSrv().put(`/api/admin/users/${grafanaUserId}/password`, {
      password: newPassword,
    })
  } catch (err) {
    console.log('Error resetPasswordUserApi : ', err)
    error = err
  }

  return { result, error }
}

const updateUserApi = async (userId: number, params: UpdateUser, t3StoreApiConfig: T3StoreApiConfig) => {
  let result = null,
    error = null

  try {
    result = await fetchPatch(`${t3StoreApiConfig.apiUrl}/users/${userId}`, params, createApiConfig(t3StoreApiConfig))
  } catch (err: any) {
    console.log('Error updateUserApi : ', err)
    error = err
  }

  return { result, error }
}

const getUserByIdApi = async (userId: Number, t3StoreApiConfig: T3StoreApiConfig) => {
  let result = null,
    error = null
  try {
    const { result: userResult } = await getQueries(
      { q: 'iot.func_get_user_by_id', param_input: userId },
      t3StoreApiConfig
    )

    const userData = mapQueriesValue<UserById>(userResult?.data)
    result = userData?.[0]
  } catch (err) {
    console.log('Error getUserByIdApi : ', err)
    error = err
  }

  return { result, error }
}

export { getUserProfileApi, addUserApi, resetUserPasswordApi, updateUserApi, getUserByIdApi }
